import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailOneOperation } from 'services/ether/case-manager/operation';
import { DetailOperationEP } from 'services/ether/case-manager/operation/types';

const useDetailOneOperation = (
    params: DetailOperationEP.Params.One,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['operation', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) => detailOneOperation({ ...params, signal }),
        ...queryOptions,
    });

    return query;
};

export default useDetailOneOperation;
