import NameIdAggregator from 'components/datatable/NameIdAggregator';
import { Column } from 'primereact/column';
import { DataTableProps } from 'primereact/datatable';
import AuthorizationStatusBadge from '../AuthorizationStatusBadge';
import { DateBadge, ObjectDisplayModal } from 'components/ethercity-primereact';
import {
    isAuthorizationFilesExportable,
    isAuthorizationRespondable,
} from 'utils/authorization';
import { useAuth } from 'hooks/context/useAuth';
import { MenuItem } from 'primereact/menuitem';
import { useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { useRef, useState } from 'react';
import { Button } from 'primereact/button';
import { useLocalization } from 'hooks/context/useLocalization';
import { CMDataTableProps } from 'components/datatable/CMDataTable/types';
import CMDataTable from 'components/datatable/CMDataTable';
import useExportAuthorizationFiles from 'hooks/queries/authorization/useExportAuthorizationFiles';
import { useAuthorizationRespond } from 'hooks/models/authorization/useAuthorizationRespond';
import { getUserDisplayName } from 'utils/user';
import { getFilterData } from 'utils/datatable';
import useDownloadEvidencesFromAuthorization from 'hooks/queries/evidence/useDownloadEvidencesFromAuthorization';
import { FilterMatchMode } from 'primereact/api';
import useShowOperationStats from 'hooks/dialogs/operation/useShowOperationStats';
import useListAuthorizationConfig from 'hooks/queries/authorization-config/useListAuthorizationConfig';
import { useProject } from 'hooks/context/project/useProject';
import YesNoBadge from 'components/display/YesNoBadge';
import { getYesNoOptions } from 'components/models/Targets/TargetsDataTable/utils';
import AuthoritySignStatusBadge from '../AuthoritySignStatusBadge';
import useListPirateBrands from 'hooks/queries/tag/useListPirateBrands';
import useListCountries from 'hooks/queries/tag/useListCountries';
import useListFlags from 'hooks/queries/tag/useListFlags';

type DetailedModel = Ether.CaseManager.Authorization.Detailed;

const RowMenu: React.FC<{
    item: DetailedModel;
    onRespondAuthorization: (item: DetailedModel, answer: boolean) => void;
    onShowDetail: (item: DetailedModel) => void;
    onStartEdit?: (item: DetailedModel) => void;
    onShowStats?: (item: { id: string; name: string }) => void;
}> = ({
    item,
    onRespondAuthorization,
    onStartEdit,
    onShowDetail,
    onShowStats,
}) => {
    const { user, permissions } = useAuth();
    const navigate = useNavigate();
    const [localization] = useLocalization();

    const menuRef = useRef<Menu>(null);

    const isFilesExportable = isAuthorizationFilesExportable({
        authorization: item,
        permissions: permissions,
        token: undefined,
    });

    const { exportFile, isExporting } = useExportAuthorizationFiles();
    const {
        downloadFile: downloadEvidences,
        isDownloading: isDownloadingEvidences,
    } = useDownloadEvidencesFromAuthorization({
        authorization_id: item._id,
    });

    const isRespondable = isAuthorizationRespondable({
        permissions: permissions,
        authorization: item,
        userId: user.data?._id,
        userRole: user.role,
    });
    const isEditable =
        item.status === 'draft' &&
        permissions.editAuthorizations &&
        !!onStartEdit &&
        item.target_group_ids &&
        item.target_group_ids.length > 0;
    const menuItems: MenuItem[] = [
        ...(permissions.getAuthorizationStats && onShowStats
            ? [
                  {
                      icon: 'pi pi-chart-bar',
                      label: localization.components.common.stats.header,
                      command: () =>
                          onShowStats({
                              id: item._id,
                              name: item.name,
                          }),
                  },
              ]
            : []),
        {
            id: 'targets',
            // icon: 'pi pi-sign-out',
            label: localization.models.target.plural,
            command: () =>
                navigate(`/authorizations/${item._id}?start_view=targets`),
        },
        {
            id: 'evidences',
            // icon: 'pi pi-sign-out',
            label: localization.models.evidence.plural,
            command: () =>
                navigate(`/authorizations/${item._id}?start_view=evidences`),
        },
        ...(isFilesExportable
            ? [
                  {
                      id: 'export-files',
                      icon: 'pi pi-download',
                      label: localization.components.common.button.exportFiles,
                      command: () =>
                          exportFile({
                              filename: null,
                              authorization_id: item._id,
                          }),
                      disabled: isExporting,
                  },
              ]
            : []),
        ...(permissions.downloadEvidencesAuthorization &&
        (item.count_data.evidences_count ?? 0) > 0
            ? [
                  {
                      id: 'download-evidences',
                      icon: 'pi pi-download',
                      label: localization.components.models.evidence.button
                          .downloadMany,
                      command: downloadEvidences,
                      disabled: isDownloadingEvidences,
                  },
              ]
            : []),
        ...(isEditable
            ? [
                  {
                      id: 'edit',
                      label: localization.components.common.button.edit,
                      icon: 'pi pi-pencil',
                      command: () => onStartEdit(item),
                  },
              ]
            : []),
        ...(isRespondable
            ? [
                  {
                      id: 'accept',
                      label: !user.role?.startsWith('operator')
                          ? localization.components.common.button.approve
                          : localization.components.common.button.confirm,
                      command: () => onRespondAuthorization(item, true),
                      icon: 'pi pi-check',
                      style: {
                          backgroundColor: 'var(--semaphore-green)',
                      },
                  },
              ]
            : []),
        ...(permissions.debugAuthorizations
            ? [
                  {
                      id: 'debug',
                      label: 'Detail data',
                      command: () => onShowDetail(item),
                  },
              ]
            : []),
    ];

    if (menuItems.length <= 0) return;

    return (
        <>
            <Menu popup ref={menuRef} model={menuItems} />
            <Button
                icon='pi pi-ellipsis-h'
                onClick={(e) => menuRef?.current?.toggle(e)}
            />
        </>
    );
};

type KeyInColumn = keyof CaseManagerApp.ModelColumns['authorization'];

export const authorizationColumnFields: { [key in KeyInColumn]: key } = {
    approved_at: 'approved_at',
    approved_by: 'approved_by',
    created_at: 'created_at',
    'created_by_data.display_name': 'created_by_data.display_name',
    evidences_count: 'evidences_count',
    status: 'status',
    targets_count: 'targets_count',
    registered_at: 'registered_at',
    authorization_config_id: 'authorization_config_id',
    'block_orders.0|isnull': 'block_orders.0|isnull',
    'block_orders.added_at': 'block_orders.added_at',
    'authority_data.status': 'authority_data.status',
    _cmapp_tags_pirate_brand: '_cmapp_tags_pirate_brand',
    _cmapp_tags_country: '_cmapp_tags_country',
    _cmapp_tags_flag: '_cmapp_tags_flag',
};
const columnFields = authorizationColumnFields;

export const authorizationFilters = {
    _cm_name_id: getFilterData(),
    [columnFields.status]: getFilterData(FilterMatchMode.EQUALS),
    [columnFields.created_at]: getFilterData(FilterMatchMode.DATE_IS),
    [columnFields.approved_at]: getFilterData(FilterMatchMode.DATE_IS),
    [columnFields.authorization_config_id]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['block_orders.0|isnull']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['block_orders.added_at']]: getFilterData(
        FilterMatchMode.DATE_IS
    ),
    [columnFields['created_by_data.display_name']]: getFilterData(
        FilterMatchMode.CONTAINS
    ),
    [columnFields['authority_data.status']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['_cmapp_tags_pirate_brand']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['_cmapp_tags_country']]: getFilterData(
        FilterMatchMode.EQUALS
    ),
    [columnFields['_cmapp_tags_flag']]: getFilterData(FilterMatchMode.EQUALS),
};

const AuthorizationDataTable: React.FC<
    DataTableProps<DetailedModel[]> &
        Omit<CMDataTableProps, 'columnConfigName'> & {
            showApproved?: boolean;
            onRespond?: () => void;
            onStartEdit?: (
                authorization: Ether.CaseManager.Authorization
            ) => void;
        }
> = ({ showApproved, onRespond, onStartEdit, ...props }) => {
    const [localization] = useLocalization();
    const project = useProject();
    const { user } = useAuth();

    const pirateBrandsQuery = useListPirateBrands();
    const countriesQuery = useListCountries();
    const flagsQuery = useListFlags();

    // TODO: REMOVE LATER
    const isEmeaProject = project.name.toLowerCase().includes('emea');

    const authorizationBadge =
        localization.components.models.authorization.badge.status;
    const defaultStatusOptions = [
        {
            label: authorizationBadge.pending.toLocaleUpperCase(),
            value: 'pending',
        },
        {
            label: authorizationBadge.approved.toLocaleUpperCase(),
            value: 'approved',
        },
        { label: authorizationBadge.done.toLocaleUpperCase(), value: 'done' },
        ...(user.role?.startsWith('authorizer')
            ? []
            : [
                  {
                      label: authorizationBadge.draft.toLocaleUpperCase(),
                      value: 'draft',
                  },
              ]),
    ];

    const {
        data: authorizationConfigData,
        isLoading: isAuthorizationConfigQueryLoading,
    } = useListAuthorizationConfig({
        project_id: project._id,
        options: {
            limit: 0,
        },
    });

    const { dialog: statsDialog, show: showStats } = useShowOperationStats();

    const [viewAuthorizationDialogOptions, setViewAuthorizationDialogOptions] =
        useState<{
            visible: boolean;
            data: Ether.CaseManager.Authorization | null;
        }>({
            visible: false,
            data: null,
        });

    const onAuthorizationViewModalShow = (
        data: Ether.CaseManager.Authorization
    ) =>
        setViewAuthorizationDialogOptions({
            visible: true,
            data: data,
        });

    const onAuthorizationViewModalHide = () =>
        setViewAuthorizationDialogOptions({
            data: null,
            visible: false,
        });

    const { RespondDialog, respondAuthorization } = useAuthorizationRespond({
        onRespond: onRespond,
    });

    const sortable = 'sortField' in props;
    const datatableLocale =
        localization.components.models.authorization.datatable;

    const hideSignedTab = user.role === 'analyst';

    return (
        <>
            <RespondDialog />
            {statsDialog}
            <ObjectDisplayModal
                header={viewAuthorizationDialogOptions.data?.name}
                visible={viewAuthorizationDialogOptions.visible}
                displayData={viewAuthorizationDialogOptions.data}
                onHide={onAuthorizationViewModalHide}
                sortKeys={false}
            />
            <CMDataTable
                columnConfigName='authorization'
                lazy
                emptyMessage={
                    localization.components.models.authorization.datatable.empty
                }
                filterOptions={{
                    _cm_name_id: {
                        placeholder: datatableLocale.selectName,
                        type: 'string',
                    },
                    [columnFields.status]: {
                        label: localization.fields.authorization.status,
                        placeholder: datatableLocale.selectStatus,
                        type: 'dropdown',
                        selectOptions: defaultStatusOptions,
                    },
                    [columnFields.created_at]: {
                        label: localization.fields.authorization.created,
                        type: 'date',
                    },
                    [columnFields['created_by_data.display_name']]: {
                        label: localization.fields.authorization.createdBy,
                        type: 'string',
                    },
                    [columnFields.approved_at]: {
                        label: localization.fields.authorization.approval,
                        type: 'date',
                    },
                    [columnFields.authorization_config_id]: {
                        label: localization.models.authorizationConfig.singular,
                        type: 'dropdown',
                        selectOptions: authorizationConfigData?.payload
                            ?.map((a) => ({
                                label: a.name,
                                value: a._id,
                            }))
                            .sort((a, b) => (a.label > b.label ? 1 : -1)),
                        loading: isAuthorizationConfigQueryLoading,
                    },
                    [columnFields['block_orders.0|isnull']]: {
                        label: localization.fields.authorization.notified,
                        type: 'dropdown',
                        selectOptions: getYesNoOptions(localization),
                    },
                    [columnFields['block_orders.added_at']]: {
                        label: localization.fields.authorization.notifiedAt,
                        type: 'date',
                    },
                    // TODO: instead of generating filter like this, allow the filters to be custom
                    [columnFields['_cmapp_tags_pirate_brand']]: {
                        label: localization.models.tag.types.pirateBrand.plural,
                        type: 'multiselect',
                        selectOptions: pirateBrandsQuery.data?.payload.map(
                            (t) => ({
                                label: t.name,
                                value: t._id,
                            })
                        ),
                        loading: pirateBrandsQuery.isLoading,
                    },
                    ...(!isEmeaProject
                        ? {}
                        : {
                              [columnFields['_cmapp_tags_country']]: {
                                  label: localization.models.tag.types.country
                                      .singular,
                                  type: 'dropdown',
                                  selectOptions:
                                      countriesQuery.data?.payload.map((t) => ({
                                          label: t.name,
                                          value: t._id,
                                      })),
                                  loading: countriesQuery.isLoading,
                              },
                          }),
                    ...(!isEmeaProject
                        ? {}
                        : {
                              [columnFields['_cmapp_tags_flag']]: {
                                  label: localization.models.tag.types.flag
                                      .singular,
                                  type: 'dropdown',
                                  selectOptions: flagsQuery.data?.payload.map(
                                      (t) => ({
                                          label: t.label,
                                          value: t._id,
                                      })
                                  ),
                                  loading: flagsQuery.isLoading,
                              },
                          }),
                    ...(hideSignedTab
                        ? {}
                        : {
                              [columnFields['authority_data.status']]: {
                                  label: localization.fields.authorization
                                      .authorityStatus,
                                  type: 'dropdown',
                                  selectOptions: [
                                      'new',
                                      'pending',
                                      'done',
                                      'available',
                                  ].map((a) => ({
                                      label: localization.components.models
                                          .authorization.badge.authorityStatus[
                                          a as keyof typeof localization.components.models.authorization.badge.authorityStatus
                                      ],
                                      value: a,
                                  })),
                              },
                          }),
                }}
                {...props}
            >
                <Column
                    field='_cm_name_id'
                    body={(data: DetailedModel) => (
                        <NameIdAggregator
                            name={data.name}
                            id={data._id}
                            navigateTo={`/authorizations/${data._id}?start_view=targets`}
                        />
                    )}
                />
                <Column
                    field={columnFields['authorization_config_id']}
                    header={localization.models.authorizationConfig.singular}
                    body={(data: DetailedModel) =>
                        data.authorization_configs_data?.[0]?.name ?? '-'
                    }
                />
                <Column
                    field={columnFields['status']}
                    header={localization.fields.authorization.status}
                    body={(data: DetailedModel) => (
                        <AuthorizationStatusBadge authorization={data} />
                    )}
                />
                <Column
                    field={columnFields['block_orders.0|isnull']}
                    header={localization.fields.authorization.notified}
                    body={(data: DetailedModel) => (
                        <YesNoBadge value={data.block_orders?.length > 0} />
                    )}
                />
                <Column
                    field={columnFields['block_orders.added_at']}
                    header={localization.fields.authorization.notifiedAt}
                    body={(data: DetailedModel) => {
                        const latest = data.block_orders.sort((a, b) =>
                            a.added_at > b.added_at ? 1 : -1
                        )[0];
                        if (!latest) return '-';
                        // TODO: gambiarra
                        if (latest.added_at instanceof Date)
                            return <DateBadge value={latest.added_at} />;
                        return <DateBadge value={new Date(latest.added_at)} />
                    }}
                />
                {!hideSignedTab && (
                    <Column
                        field={columnFields['authority_data.status']}
                        header={
                            localization.fields.authorization.authorityStatus
                        }
                        body={(data: DetailedModel) => {
                            const processType =
                                data.authorization_configs_data?.[0]
                                    ?.process_type;
                            if (processType !== 'judicial') return '-';
                            return (
                                <AuthoritySignStatusBadge
                                    authorization={data}
                                />
                            );
                        }}
                    />
                )}
                <Column
                    field={columnFields['targets_count']}
                    header={localization.models.target.plural}
                    body={(data: DetailedModel) =>
                        data.count_data.targets_count ?? '-'
                    }
                />
                <Column
                    field={columnFields['evidences_count']}
                    header={localization.models.evidence.plural}
                    body={(data: DetailedModel) =>
                        data.count_data.evidences_count ?? '-'
                    }
                />
                <Column
                    field={columnFields['created_at']}
                    header={localization.fields.authorization.created}
                    body={(data: DetailedModel) => (
                        <DateBadge value={data.created_at} />
                    )}
                    sortable={sortable}
                    dataType='date'
                />
                <Column
                    field={columnFields['created_by_data.display_name']}
                    header={localization.fields.authorization.createdBy}
                    body={(data: DetailedModel) => {
                        const user = data.created_by_data?.[0];
                        if (!user) return '-';
                        return getUserDisplayName(user);
                    }}
                />
                <Column
                    field={columnFields['registered_at']}
                    header={localization.fields.authorization.registeredAt}
                    body={(data: DetailedModel) =>
                        data.registered_at ? (
                            <DateBadge value={data.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                    sortable={sortable}
                />
                {showApproved && (
                    <Column
                        field={columnFields['approved_at']}
                        header={localization.fields.authorization.approval}
                        body={(data: DetailedModel) =>
                            data.approved_at ? (
                                <DateBadge value={data.approved_at} />
                            ) : (
                                '-'
                            )
                        }
                        sortable={sortable}
                        dataType='date'
                    />
                )}
                {showApproved && (
                    <Column
                        field={columnFields['approved_by']}
                        header={localization.fields.authorization.approvedBy}
                        body={(data: DetailedModel) => {
                            const approvedBy =
                                data.authorizer_associations_data?.find(
                                    (auth) => auth.response?.accepted
                                );
                            if (!approvedBy) return '-';
                            const approvedByUser = approvedBy.users_data?.find(
                                (u) => !!u._id
                            );
                            if (!approvedByUser) return '-';
                            return getUserDisplayName(approvedByUser);
                        }}
                    />
                )}
                <Column
                    field='actions'
                    body={(data: DetailedModel) => (
                        <RowMenu
                            item={data}
                            onRespondAuthorization={respondAuthorization}
                            onShowDetail={onAuthorizationViewModalShow}
                            onStartEdit={onStartEdit}
                            onShowStats={showStats}
                        />
                    )}
                />
            </CMDataTable>
        </>
    );
};

export default AuthorizationDataTable;
