import { FilterOption } from 'components/datatable/CMDataTable/types';
import { CMLocalization } from 'static/language';
import {
    getDefaultTypeOptions,
    getOnOffOptions,
    getSafeIpOptions,
    getTargetMeta,
    getYesNoOptions,
} from './utils';
import TargetEnrichmentField from '../TargetEnrichmentField';
import YesNoBadge from 'components/display/YesNoBadge';
import { DateBadge } from 'components/ethercity-primereact';
import TargetTypeBadge from '../TargetTypeBadge';
import { objetifyNestedTagList, translateSafeIpReasons } from 'utils/target';
import ReservedIpBadge from '../ReservedIpBadge';
import { Badge } from 'primereact/badge';
import AdaptableTooltip from 'components/misc/AdaptableTooltip';
import { localizeDistance } from 'utils/dateUtils';
import DisplayPending from 'components/misc/CellDisplayPending';
import DisplayError from 'components/misc/CellDisplayError';
import { Column } from 'primereact/column';
import _ from 'lodash';

type DetailedModel = Ether.CaseManager.Target.Detailed;

type KeyInColumn = keyof CaseManagerApp.ModelColumns['targets'];

export const targetDataTableColumnFields: { [key in KeyInColumn]: key } = {
    type: 'type',
    tags: 'tags',
    enrichment_status: 'enrichment_status',
    created_at: 'created_at',

    'list_data.accessible_ports': 'list_data.accessible_ports',
    'list_data.asn': 'list_data.asn',
    'list_data.br_domains_count': 'list_data.br_domains_count',
    'list_data.country': 'list_data.country',
    'list_data.highest_grading': 'list_data.highest_grading',
    'list_data.hosted_domains_count': 'list_data.hosted_domains_count',
    'list_data.hosted_domains_list': 'list_data.hosted_domains_list',
    'list_data.official_domain': 'list_data.official_domain',
    'list_data.reserved_ip': 'list_data.reserved_ip',
    'list_data.safe_ip': 'list_data.safe_ip',
    'list_data.whois_owner': 'list_data.whois_owner',
    'list_data.domain_age': 'list_data.domain_age',
    'list_data.dns_ns': 'list_data.dns_ns',
    'list_data.ips_list': 'list_data.ips_list',

    // 'meta.pirate_brand': 'meta.pirate_brand',
    // 'meta.porta': 'meta.porta',
    // 'meta.protocolo': 'meta.protocolo',
    // 'meta.pre-validado': 'meta.pre-validado',
    // 'meta.modelo': 'meta.modelo',
    // 'meta.app': 'meta.app',
    // 'meta.app_versao': 'meta.app_versao',
    // 'meta.request_type': 'meta.request_type',
    // 'meta.rule': 'meta.rule',
    // 'meta.illegality_report': 'meta.illegality_report',
    // 'meta.similarity_test': 'meta.similarity_test',
    // 'meta.delisting': 'meta.delisting',
    // 'meta.blocking_status': 'meta.blocking_status',
    // 'meta.countries': 'meta.countries',
    // 'meta.isp': 'meta.isp',
    // 'meta.ports': 'meta.ports',

    compiled_tags: 'compiled_tags',

    'external_services_data.block_checker':
        'external_services_data.block_checker',
    'external_services_data.similarweb': 'external_services_data.similarweb',
    'external_services_data.delisting_checker':
        'external_services_data.delisting_checker',
    'external_services_data.onoff': 'external_services_data.onoff',

    omit_pre_reprove: 'omit_pre_reprove',
    answer: 'answer',
    answered_at: 'answered_at',
    blocked_at: 'blocked_at',
    notified: 'notified',

    _cmapp_tags_pirate_brand: '_cmapp_tags_pirate_brand',
    _cmapp_tags_country: '_cmapp_tags_country',
    _cmapp_tags_flag: '_cmapp_tags_flag',
};

/**
 * Generates columns dynamically for the TargetsDataTable
 * @param project The currenct project in context
 * @param refAuthorization Referencing authorization, if existing
 * @param localization The localization object
 * @param sortable If the table is sortable
 * @param extraMetaFields Which extra meta fields should be considered
 * @param extraFilters Which extra filters should bre added
 */
export const generateTargetColumnsAndFilters = ({
    project,
    refAuthorization,
    localization,
    sortable,
    extraMetaFields,
    extraFilters,
}: {
    project: Ether.CaseManager.Project;
    refAuthorization: Ether.CaseManager.Authorization.Detailed | undefined;
    localization: CMLocalization.Localization;
    sortable: boolean;
    extraMetaFields?: string[];
    extraFilters?: {
        [key in KeyInColumn]?: FilterOption & {
            render: (columns: Partial<Record<KeyInColumn, boolean>>) => boolean;
        };
    };
}) => {
    const config = project.app_config;

    const datatableLocale = localization.components.models.target.datatable;
    const fields = localization.fields.target;

    const defaultTypeOptions = getDefaultTypeOptions(localization);
    const onOffOptions = getOnOffOptions(localization);
    const safeIpOptions = getSafeIpOptions(localization);
    const yesNoOptions = getYesNoOptions(localization);

    const filters: Record<string, FilterOption> = {
        _cm_name_id: {
            placeholder: datatableLocale.selectValue,
            type: 'string',
        },
    };
    const columns: JSX.Element[] = [];

    const returnValue = {
        filters,
        columns,
    };

    if (!config?.targets_table) return returnValue;

    type ConfigObject = Required<typeof config.targets_table>;

    const mapLocale: Record<ConfigObject[keyof ConfigObject], string> = {
        type: fields.type,
        tags: localization.models.tag.plural,
        enrichment_status: fields.enrichment,
        'list_data.highest_grading': fields.grading,
        'list_data.hosted_domains_list': fields.hostingHistoryDns,
        'list_data.hosted_domains_count': fields.domainsCount,
        'list_data.br_domains_count': fields.domainsBrCount,
        'list_data.country': fields.country,
        'list_data.asn': fields.asn,
        'list_data.accessible_ports': fields.accessiblePorts,
        'list_data.safe_ip': fields.safeIp,
        'list_data.reserved_ip': fields.reservedIp,
        'list_data.official_domain': fields.officialDomain,
        'list_data.dns_ns': fields.dnsNameservers,
        'list_data.domain_age': fields.domainAge,
        'list_data.ips_list': fields.ipsList,
        'list_data.whois_owner': fields.whoisOwner,
        compiled_tags: fields.pirateBrands,
        'external_services_data.block_checker': fields.blockCheckerProgress,
        'external_services_data.similarweb': fields.similarWeb,
        'external_services_data.delisting_checker': fields.delistingChecker,
        'external_services_data.onoff': fields.onoffStatus,
        created_at: fields.created,
        notified: fields.notified,
    };
    const mapTable: Partial<Record<ConfigObject[keyof ConfigObject], boolean>> =
        {};

    // TODO: REMOVE
    const isEmea = project.name.toLowerCase().includes('emea');

    const columnsRendersConditions: Partial<
        Record<ConfigObject[keyof ConfigObject], boolean>
    > = {
        'external_services_data.delisting_checker': !!refAuthorization,
        'external_services_data.block_checker': !!refAuthorization,
    };

    Object.values(config.targets_table)
        .filter((field) => {
            if (!(field in columnsRendersConditions)) return true;
            return columnsRendersConditions[field];
        })
        .map((field) => {
            const key = targetDataTableColumnFields[field];
            if (!key) return null;
            mapTable[field] = true;
            const header = mapLocale[key];
            const props = {
                key: key,
                field: key,
                header: header,
                body: (data: any) => {
                    const value = key
                        .split('.')
                        .reduce((a, b) => (a ?? {})[b], data);
                    if (typeof value === 'boolean')
                        return <YesNoBadge value={value} />;
                    if (value instanceof Date)
                        return <DateBadge value={value} />;
                    if (Array.isArray(value)) {
                        if (value.length <= 0) return '-';
                        if (value.length <= 5) return value.join(', ');
                        return value.slice(0, 5).join(', ') + ' (...)';
                    }
                    return (
                        <div className='max-w-full w-max'>{value ?? '-'}</div>
                    );
                },
                sortable: false,
            };
            switch (field) {
                case 'type':
                    filters[key] = {
                        label: header,
                        placeholder: datatableLocale.selectType,
                        type: 'dropdown',
                        selectOptions: defaultTypeOptions,
                    };
                    props.body = (data: Ether.CaseManager.Target) => (
                        <TargetTypeBadge target={data} />
                    );
                    break;
                case 'compiled_tags':
                    props.body = (data: DetailedModel) => {
                        const compiledTags = data.compiled_tags;
                        if (!compiledTags) return '-';
                        const tags = Object.entries(compiledTags);
                        if (tags.length <= 0) return '-';
                        return (
                            <pre className='flex flex-col'>
                                {tags.map(([key, value]) => {
                                    return (
                                        <span key={key + value}>{`${key} : ${
                                            Array.isArray(value)
                                                ? value.join(', ')
                                                : value
                                        }`}</span>
                                    );
                                })}
                            </pre>
                        );
                    };
                    break;
                case 'tags':
                    props.body = (data: DetailedModel) => {
                        if (!refAuthorization) {
                            const compiledTags =
                                data.compiled_authorizations_tags;
                            if (!compiledTags || compiledTags.length <= 0)
                                return '-';
                            return (
                                <pre className='flex flex-col'>
                                    {Object.entries(
                                        objetifyNestedTagList(compiledTags)
                                    ).map(([key, value]) => (
                                        <span
                                            key={key + value}
                                        >{`${key} : ${value}`}</span>
                                    ))}
                                </pre>
                            );
                        }
                        let targetAuth = data?.authorizations?.find(
                            (a) => a?._id === refAuthorization._id
                        );
                        if (!targetAuth) {
                            targetAuth = data.removed_authorizations?.find(
                                (a) => a?._id === refAuthorization?._id
                            );
                        }
                        if (!targetAuth?.tags?.length) return '-';
                        return (
                            <pre className='flex flex-col'>
                                {Object.entries(
                                    objetifyNestedTagList(targetAuth.tags)
                                ).map(([key, value]) => (
                                    <span
                                        key={key + value}
                                    >{`${key} : ${value}`}</span>
                                ))}
                            </pre>
                        );
                    };
                    break;
                case 'enrichment_status':
                    props.body = (data: DetailedModel) => (
                        <TargetEnrichmentField target={data} />
                    );
                    break;
                case 'list_data.highest_grading':
                    filters[key] = {
                        label: header,
                        placeholder: datatableLocale.inputGrading,
                        type: 'number',
                    };
                    props.sortable = sortable;
                    break;
                case 'list_data.hosted_domains_list':
                    props.body = (data: DetailedModel) => {
                        const domains = data.list_data?.hosted_domains_list;
                        if (!domains || domains.length <= 0) return '-';
                        return (
                            <div className='flex flex-col gap-1 items-start'>
                                {domains.map((dns, index) => (
                                    <span key={dns + '@' + index}>{dns}</span>
                                ))}
                            </div>
                        );
                    };
                    break;
                case 'list_data.official_domain':
                    filters[key] = {
                        label: header,
                        type: 'dropdown',
                        selectOptions: yesNoOptions,
                    };
                    break;
                case 'list_data.hosted_domains_count':
                    props.sortable = sortable;
                    break;
                case 'list_data.br_domains_count':
                    props.sortable = sortable;
                    break;
                case 'list_data.reserved_ip':
                    filters[key] = {
                        label: localization.fields.target.reservedIp,
                        type: 'dropdown',
                        selectOptions: yesNoOptions,
                    };
                    break;
                case 'list_data.country':
                    filters[key] = {
                        label: localization.fields.target.country,
                        type: 'string',
                        hideMatchMode: true,
                    };
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.country ?? '-';
                    };
                    break;
                case 'list_data.asn':
                    props.body = (data: DetailedModel) => {
                        if (data.list_data?.reserved_ip)
                            return <ReservedIpBadge />;
                        return data.list_data?.asn ?? '-';
                    };
                    break;
                case 'list_data.safe_ip':
                    filters[key] = {
                        label: localization.fields.target.safeIp,
                        type: 'dropdown',
                        selectOptions: safeIpOptions,
                    };
                    props.body = (data: DetailedModel) => {
                        const isSafe: boolean | null | undefined =
                            data.list_data?.safe_ip;
                        const safeIpReasons = translateSafeIpReasons(
                            data,
                            localization
                        );
                        const classN = `span-safeip-target-${data._id}`;

                        return (
                            <>
                                <div style={{ minWidth: '40px' }}>
                                    {isSafe != null ? (
                                        isSafe ? (
                                            <Badge
                                                severity={'success'}
                                                value={'Go'}
                                            />
                                        ) : (
                                            <>
                                                <AdaptableTooltip
                                                    target={'.' + classN}
                                                >
                                                    <div className='flex flex-col gap-1'>
                                                        {safeIpReasons.map(
                                                            (r) => (
                                                                <span key={r}>
                                                                    {r}
                                                                </span>
                                                            )
                                                        )}
                                                    </div>
                                                </AdaptableTooltip>
                                                <Badge
                                                    className={classN}
                                                    severity={'warning'}
                                                    value={'Review'}
                                                />
                                            </>
                                        )
                                    ) : (
                                        '-'
                                    )}
                                </div>
                            </>
                        );
                    };
                    break;
                case 'notified':
                    filters[key] = {
                        label: localization.fields.target.notified,
                        type: 'dropdown',
                        selectOptions: yesNoOptions,
                    };
                    break;
                case 'list_data.domain_age':
                    props.body = (data: DetailedModel) => {
                        if (!data.list_data?.domain_age) return '-';
                        const now = new Date();
                        const text = localizeDistance(
                            now,
                            data.list_data.domain_age,
                            localization
                        );
                        return <div className='max-w-full w-max'>{text}</div>;
                    };
                    break;
                case 'external_services_data.delisting_checker':
                    props.header = isEmea ? 'Delisting status' : header;
                    props.body = (item: DetailedModel) => {
                        const data =
                            item.external_services_data?.delisting_checker?.[0];
                        if (!data) return <Badge value='NEW' severity={null} />;
                        if (data.status === 'pending')
                            return <DisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <DisplayError />;
                        if (!data.values.status) return '-';
                        const value = data.values.status.toLocaleUpperCase();
                        return (
                            <Badge
                                value={
                                    isEmea
                                        ? value === 'ONLINE'
                                            ? 'INDEXED'
                                            : 'NOT_INDEXED'
                                        : value
                                }
                                severity={
                                    data.values.status === 'online'
                                        ? 'success'
                                        : data.values.status === 'offline'
                                        ? 'danger'
                                        : 'info'
                                }
                            />
                        );
                    };
                    break;
                case 'external_services_data.block_checker':
                    props.body = (item: DetailedModel) => {
                        const data =
                            item.external_services_data?.block_checker?.[0];
                        if (!data) return <Badge value='NEW' severity={null} />;
                        if (data.status === 'pending')
                            return <DisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <DisplayError />;
                        return (
                            <Badge
                                value={
                                    (
                                        data.values.block_effectiveness * 100
                                    ).toFixed(2) + '%'
                                }
                                severity={
                                    data.values.block_effectiveness >= 1
                                        ? 'success'
                                        : 'warning'
                                }
                            />
                        );
                    };
                    break;
                case 'external_services_data.similarweb':
                    props.body = (item: DetailedModel) => {
                        const data =
                            item.external_services_data?.similarweb?.[0];
                        if (!data) return '-';
                        if (data.status === 'pending')
                            return <DisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <DisplayError />;

                        const { visits, trend } = data.values;
                        const formatter = Intl.NumberFormat(
                            localization.getLanguage(),
                            { notation: 'compact' }
                        );
                        const formattedVisits = formatter.format(visits);
                        const formattedTrend = (trend * 100).toFixed(2) + '%';

                        const trendClassname =
                            trend === 0
                                ? ''
                                : trend > 0
                                ? 'text-green-500'
                                : 'text-red-500';

                        const trendElement = (
                            <div
                                className={`flex flex-row gap-2 font-bold ${trendClassname}`}
                            >
                                {trend !== null && (
                                    <span>{formattedTrend}</span>
                                )}
                                {trend !== null &&
                                    trend !== 0 &&
                                    (trend > 0 ? (
                                        <i className='pi pi-arrow-up-right' />
                                    ) : (
                                        <i className='pi pi-arrow-down-right' />
                                    ))}
                            </div>
                        );

                        const tooltipClass = `similar-${item._id}`;

                        return (
                            <div
                                className={`max-w-full w-max flex flex-col gap-1 text-left ${tooltipClass}`}
                            >
                                <AdaptableTooltip
                                    target={'.' + tooltipClass}
                                    // TODO: localization
                                >{`Total visits: ${new Intl.NumberFormat().format(
                                    visits
                                )}`}</AdaptableTooltip>
                                {formattedVisits}
                                {trendElement}
                            </div>
                        );
                    };
                    break;
                case 'external_services_data.onoff':
                    filters[key] = {
                        label: header,
                        type: 'dropdown',
                        selectOptions: onOffOptions,
                    };
                    props.body = (item: DetailedModel) => {
                        const data = item.external_services_data?.onoff?.[0];
                        if (!data) return <Badge value='NEW' severity={null} />;
                        if (data.status === 'pending')
                            return <DisplayPending />;
                        if (data.status === 'error' || !data.values)
                            return <DisplayError />;
                        const value = data.values.status;
                        if (!value) return '-';
                        return (
                            <Badge
                                value={value.toLocaleUpperCase()}
                                severity={
                                    value === 'online' ? 'success' : 'danger'
                                }
                            />
                        );
                    };
                    break;
            }
            if (key.startsWith('meta.') && !refAuthorization) return null;
            return <Column {...props} />;
        })
        .forEach((c) => {
            if (!c) return;
            columns.push(c);
        });

    extraMetaFields?.forEach((key) => {
        const metaKey = `meta.${key}`;
        const props = {
            key: metaKey,
            field: metaKey,
            header: _.upperFirst(key),
            body: (data: any) => {
                const meta = getTargetMeta({
                    target: data,
                    field: metaKey,
                    refAuthorization,
                });
                return <div className='max-w-full w-max'>{meta ?? '-'}</div>;
            },
            sortable: false,
        };
        columns.push(<Column {...props} />);
    });

    filters[targetDataTableColumnFields['omit_pre_reprove']] = {
        type: 'multiselect',
        label: localization.fields.target.omitPreRejected,
        selectOptions: [
            {
                label: localization.components.models.target.badge
                    .preReprovedOmitOptions.recently_notified,
                value: 'recently_notified',
            },
            {
                label: localization.components.models.target.badge
                    .preReprovedOmitOptions.same_operation,
                value: 'same_operation',
            },
            {
                label: localization.components.models.target.badge
                    .preReprovedOmitOptions.unreversible,
                value: 'unreversible',
            },
        ],
    };
    Object.entries(extraFilters ?? {}).forEach(([key, value]) => {
        if (!value.render(mapTable)) return;
        filters[key] = value;
    });
    return returnValue;
};
