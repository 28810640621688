import { useMutation } from '@tanstack/react-query';
import LightDialog from 'components/display/LightDialog';
import { Datepicker, InputText } from 'components/ethercity-primereact';
import { useProject } from 'hooks/context/project/useProject';
import { useAuth } from 'hooks/context/useAuth';
import { useLocalization } from 'hooks/context/useLocalization';
import { useToast } from 'hooks/context/useToast';
import useQueryRefresh from 'hooks/queries/useQueryRefresh';
import { Button } from 'primereact/button';
import { useCallback, useState } from 'react';
import { upsertOperation } from 'services/ether/case-manager/operation';
import { getErrorToast } from 'utils/errorHandler';

const CreateOperationDialog: React.FC<{
    onCreate?: (id: string) => void;
    onHide: () => void;
}> = ({ onCreate, onHide }) => {
    const [name, setName] = useState('');
    const project = useProject();
    const { user } = useAuth();

    const [localization] = useLocalization();
    const { hardRefresh } = useQueryRefresh();
    const toast = useToast();

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    const modalLocalization = localization.components.models.operation.views.upsertModal;

    const invalidDatesMessage = modalLocalization.errors.invalidDates;
    const equalDatesMessage = modalLocalization.errors.equalDates;

    const datesInvalid = (startDate && endDate && endDate < startDate )?? false;
    const datesEqual =
        (startDate && endDate && startDate.getTime() === endDate.getTime()) ??
        false;

    const mutation = useMutation({
        mutationFn: () => {
            if (!user.data) throw new Error('expected user');
            return upsertOperation({
                name: name,
                start_date: startDate,
                end_date: endDate,
                is_active: true,
                project_id: project._id,
            });
        },
        onSuccess: (operation) => {
            hardRefresh(['operation']);
            toast.show({
                severity: 'success',
                life: 5000,
                summary:
                    localization.endpoints.operation.create_operation
                        .createSuccess,
            });
            if (onCreate) onCreate(operation._id);
            onHide();
        },
        onError: (e: Error) =>
            toast.show(getErrorToast(e.message, localization)),
    });

    const dateError = datesInvalid || datesEqual;
    const invalidForm = dateError || name === '';
    const isDisabled = invalidForm || mutation.isPending;
    const isLoading = mutation.isPending;

    return (
        <LightDialog
            visible={true}
            onHide={onHide}
            header={localization.components.models.operation.button.new}
            footer={
                <>
                    <Button
                        label={localization.components.common.button.save}
                        onClick={() => mutation.mutate()}
                        disabled={isDisabled}
                        loading={isLoading}
                    />
                    <Button
                        label={localization.components.common.button.cancel}
                        severity='danger'
                        onClick={onHide}
                    />
                </>
            }
        >
            <InputText
                label={localization.fields.operation.name}
                value={name}
                onChange={(e) => setName(e.target.value)}
                required={true}
                wrapperStyle={{
                    width: '100%',
                }}
            />
            <div className='flex flex-row gap-2'>
                <Datepicker
                    label={localization.fields.operation.startDate}
                    type='datetime-local'
                    required={false}
                    value={startDate}
                    onChange={(e) => setStartDate(e)}
                />
                -
                <Datepicker
                    label={localization.fields.operation.endDate}
                    type='datetime-local'
                    required={false}
                    value={endDate}
                    onChange={(e) =>  setEndDate(e)}
                />
            </div>
            {datesInvalid && <span className='p-error'>{invalidDatesMessage}</span>}
            {datesEqual && <span className='p-error'>{equalDatesMessage}</span>}
        </LightDialog>
    );
};

const useCreateOperationDialog = (options?: {
    onCreate?: (id: string) => void;
}) => {
    const [visible, setVisible] = useState(false);

    const hideDialog = () => setVisible(false);
    const showDialog = () => setVisible(true);

    const element = useCallback(() => {
        return (
            visible && (
                <CreateOperationDialog
                    onHide={hideDialog}
                    onCreate={options?.onCreate}
                />
            )
        );
    }, [visible, options?.onCreate]);

    return {
        element,
        show: showDialog,
        hide: hideDialog,
    };
};

export default useCreateOperationDialog;
