import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyBlockOrderForSelect } from 'services/ether/case-manager/block-orders';
import { DetailBlockOrderEP } from 'services/ether/case-manager/block-orders/types';

const useDetailBlockOrderSelect = (
    params: DetailBlockOrderEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['block-order', 'detail', 'select', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyBlockOrderForSelect({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailBlockOrderSelect;
