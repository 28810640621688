import { useLocalization } from 'hooks/context/useLocalization';
import { SelectOneItemProps } from 'components/form/SelectItems/types';
import { DetailTagEP } from 'services/ether/case-manager/tags/types';
import useListCountries from 'hooks/queries/tag/useListCountries';
import SelectOneItem from 'components/form/SelectItems/SelectOneItem';

const SelectOneCountry: React.FC<
    SelectOneItemProps<Ether.CaseManager.Tag, DetailTagEP.Filters.Options>
> = (props) => {
    const [localization] = useLocalization();

    const query = useListCountries();

    return (
        <SelectOneItem
            title={localization.models.tag.types.country.singular}
            placeholder={
                localization.components.models.tag.types.country.form
                    .selectOnePlaceholder
            }
            label={localization.models.tag.types.country.singular}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectOneCountry;
