import { getApiBase } from 'services/ether/base';
import { GetDashboardEP } from './types';

/**
 * Makes a call to the get-dashboard endpoint and retrieves the embed info.
 * @param object.params.dashboard_slug - The specific dashboard to be retrieve from the API
 * @param object.signal - The AbortSignal instance to cancel the request at any time
 * @returns object Result with the URL and height in pixels of the embed
 * @returns object.url The URL for the embed
 * @returns object.height The embed height in pixels
 */
export const getDashboardEmbed = async ({
    params,
    signal,
}: {
    params: GetDashboardEP.Params;
    signal?: AbortSignal;
}) => {
    const result = await getApiBase<GetDashboardEP.Result>({
        endpoint: '/get-dashboard',
        params,
        signal,
    });
    return result;
};
