export const defaultPermissions: {
    [key in keyof CaseManagerApp.Permissions]: boolean | null;
} = {
    accessAdminPanel: null,

    viewUsers: null,
    upsertUsers: null,
    deleteUsers: null,
    debugUsers: null,
    listUsersByProject: null,

    viewCompanies: null,
    upsertCompanies: null,

    viewProjects: null,
    createProjects: null,
    createProjectWithDifferentGroup: null,
    editProjects: false,
    deleteProjects: false,
    debugProjects: null,
    downloadTargetsTemplate: null,

    viewTags: null,

    viewTargets: null,
    createTargets: null,
    editTargets: false,
    deleteTargets: false,
    debugTargets: null,
    exportTargets: null,
    removeTargetValidation: null,
    parseTargetList: null,
    updateTargetOrderStatus: null,

    viewAuthorizations: null,
    detailAuthorizations: null,
    editAuthorizations: null,
    deleteAuthorizations: false,
    debugAuthorizations: null,
    insertAuthorizations: null,
    respondAuthorization: null,
    exportAuthorizationFiles: null,
    exportAuthorizationTarget: null,
    joinAuthorizations: null,
    duplicateAuthorization: null,
    sendAuthorizationToAuthoritySign: null,
    getAuthorizationStats: null,
    changeAuthorizationFlow : null,

    downloadFile: null,

    viewEvidences: null,
    debugEvidences: null,
    deleteEvidences: false,
    downloadEvidencesAuthorization: null,
    downloadEvidencesAuthorizationTarget: null,

    viewBlockHistory: null,

    viewBlockOrders: null,
    debugBlockOrders: null,
    insertBlockOrders: null,
    respondBlockOrder: null,
    exportBlockOrderFiles: null,
    getBlockOrderStats: null,

    viewUnblockOrders: null,
    debugUnblockOrders: null,
    insertUnblockOrders: null,
    respondUnblockOrder: null,

    insertOperation: null,
    updateOperationOrder: null,
    updateOperationAuthorization : null,
    viewOperations: null,
    getOperationStats: null,

    viewMonitors: null,
    insertMonitors: null,
    debugMonitors: null,

    debugOperators: null,
    importOperatorsCSV: null,
    viewOperators: null,

    debugOperatorsOrder: null,

    viewDocuments: null,
    downloadDocuments: null,
    debugDocuments: null,
    insertDocuments: null,

    detailOperatorCompany: null,

    viewAuthorizationConfigs: null,

    getDashboard: null,
};

export const getUserPermissions = (
    role: Ether.CaseManager.Role | undefined,
    permissions: Ether.MeInfo['permissions'],
    is_superadmin: boolean
): CaseManagerApp.Permissions => {
    const userPermissions = { ...defaultPermissions };

    if (is_superadmin) {
        Object.keys(userPermissions).forEach((key) => {
            const value =
                userPermissions[key as keyof CaseManagerApp.Permissions];
            if (value === null)
                userPermissions[key as keyof CaseManagerApp.Permissions] = true;
        });
    } else {
        const uPerms: {
            [key in Ether.Endpoints]?: true;
        } = {};

        (permissions ?? []).forEach((perm) => (uPerms[perm] = true));

        const permissionsMap: {
            [key in keyof CaseManagerApp.Permissions]?: boolean | undefined;
        } = {
            accessAdminPanel: uPerms['upsert-company'] || uPerms['upsert-user'],

            viewUsers: uPerms['list-users'] && role === 'admin',
            upsertUsers: uPerms['upsert-user'],
            listUsersByProject: uPerms['list-users'],
            deleteUsers: uPerms['remove-user'],

            viewCompanies: uPerms['list-company'] || uPerms['detail-company'],
            upsertCompanies: uPerms['upsert-company'],

            viewProjects: uPerms['list-project'],
            deleteProjects: uPerms['delete-project'],
            createProjects:
                uPerms['create-project'] || uPerms['upsert-project'],
            createProjectWithDifferentGroup:
                (uPerms['create-project'] || uPerms['upsert-project']) &&
                uPerms['list-groups'],
            editProjects: uPerms['upsert-project'],
            downloadTargetsTemplate: uPerms['download-template-targets'],

            viewTags: uPerms['detail-tag'],

            viewTargets: uPerms['detail-target'],
            createTargets: uPerms['create-target'],
            editTargets: uPerms['update-target'],
            deleteTargets: uPerms['delete-target'],
            removeTargetValidation: uPerms['remove-target-validation'],
            parseTargetList: uPerms['parse-target-list'],
            updateTargetOrderStatus: uPerms['update-target-order-status'],

            viewAuthorizations: uPerms['detail-authorization'],
            detailAuthorizations: uPerms['detail-authorization'],
            insertAuthorizations: uPerms['insert-authorization'],
            editAuthorizations: uPerms['edit-authorization'],
            respondAuthorization: uPerms['respond-authorization'],
            exportAuthorizationFiles: uPerms['export-authorization-files'],
            exportAuthorizationTarget: uPerms['export-authorization-target'],
            joinAuthorizations: uPerms['join-authorization'],
            duplicateAuthorization: uPerms['duplicate-authorization'],
            sendAuthorizationToAuthoritySign: uPerms['send-to-authority-sign'],
            getAuthorizationStats: uPerms['get-authorization-stats'],
            changeAuthorizationFlow : uPerms['change-authorization-flow'],

            downloadFile: uPerms['download-file'],

            viewEvidences: uPerms['detail-evidence'],
            deleteEvidences: uPerms['delete-evidence'],
            downloadEvidencesAuthorization:
                uPerms['download-evidences-authorization'],
            downloadEvidencesAuthorizationTarget:
                uPerms['download-evidences-zip'],

            viewBlockHistory: uPerms['get-order-history'],

            viewBlockOrders: uPerms['detail-block-order'],
            insertBlockOrders: uPerms['upsert-block-order'],
            respondBlockOrder: uPerms['accept-order'],
            exportBlockOrderFiles: uPerms['export-block-order-files'],
            getBlockOrderStats: uPerms['get-block-order-stats'],

            viewUnblockOrders: uPerms['detail-unblock-order'],
            insertUnblockOrders: uPerms['upsert-unblock-order'],
            respondUnblockOrder: uPerms['accept-order'],

            viewOperations: uPerms['detail-operation'],
            insertOperation: uPerms['upsert-operation'],
            updateOperationOrder: uPerms['update-operation-order'],
            updateOperationAuthorization: uPerms['update-operation-authorization'],
            getOperationStats: uPerms['get-operation-stats'],

            viewMonitors: uPerms['detail-monitoring'],
            insertMonitors: uPerms['insert-monitoring'],

            viewDocuments: uPerms['detail-document'],
            downloadDocuments: uPerms['download-file'],
            insertDocuments: uPerms['upsert-document'],

            viewOperators: uPerms['detail-operator'],
            importOperatorsCSV: uPerms['import-operator'],

            exportTargets: uPerms['export-target'],

            detailOperatorCompany: uPerms['detail-operator-company'],

            viewAuthorizationConfigs: uPerms['detail-authorization-config'],

            getDashboard: uPerms['get-dashboard'],
        };

        Object.entries(permissionsMap).forEach(([key, value]) => {
            const permKey = key as keyof CaseManagerApp.Permissions;
            if (!value) return;
            if (defaultPermissions[permKey] === false) return;
            userPermissions[permKey] = true;
        });
    }

    Object.entries(userPermissions).forEach(([key, value]) => {
        if (value === null)
            userPermissions[key as keyof CaseManagerApp.Permissions] = false;
    });
    return userPermissions as CaseManagerApp.Permissions;
};
