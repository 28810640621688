import api from 'services/ether/api';
import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import { DetailUnblockOrderEP, UpsertUnblockOrderEP } from './types';
import { listBase, postApiBase } from 'services/ether/base';
import _ from 'lodash';

const _detailUnblockOrder = ({
    project_id,
    options,
    signal,
}: DetailUnblockOrderEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailUnblockOrderEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        get_target_info: [
            'get_target_info',
            devFilters.get_target_info ? 'true' : undefined,
        ],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
        operation_id: ['operation_id', devFilters.operation_id],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge({}, options?.rawFilters, mappedFilters, {
        project_id,
    });
    return listBase<Ether.CaseManager.UnblockOrder.Detailed[]>({
        endpoint: '/detail-unblock-order',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailManyUnblockOrder = ({
    project_id,
    options,
    signal,
}: DetailUnblockOrderEP.Params.Many) => {
    return _detailUnblockOrder({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: ['created_by_data', 'total_targets'],
            },
        },
        signal,
    });
};

export const detailManyUnblockOrderForHistory = ({
    project_id,
    options,
    signal,
}: DetailUnblockOrderEP.Params.Many) => {
    return _detailUnblockOrder({
        project_id,
        options: {
            ...options,
            devFilters: {
                ...options?.devFilters,
                additional_fields: [
                    'created_by_data',
                    'authorization_configs_data',
                ],
            },
        },
        signal,
    });
};

export const detailOneUnblockOrder = async ({
    _id,
    project_id,
    signal,
}: DetailUnblockOrderEP.Params.One) => {
    const { payload } = await _detailUnblockOrder({
        project_id,
        options: {
            devFilters: {
                _id: _id,
                get_target_info: true,
                additional_fields: [
                    'created_by_data',
                    'operators_order_data',
                    'operators_order_data.operators_data',
                    'operators_order_data.operators_data.users_data',
                    'operators_order_data.operators_data.last_notification_data',
                    'operation_data',
                    'total_documents',
                    'total_operators',
                    'total_targets',
                ],
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const upsertUnblockOrder = async (
    data: UpsertUnblockOrderEP.UpsertData
) => {
    const postData = {
        ...data,
        block_order_ids: data.block_order_ids.map((id) => ({ $oid: id })),
        start_date: data.start_date.toISOString(),
    };
    return postApiBase<UpsertUnblockOrderEP.Result>({
        endpoint: '/upsert-unblock-order',
        data: postData,
    });
};

export const respondUnblockOrder = ({
    accepted,
    ...rest
}: {
    accepted: boolean;
} & (
    | {
          token: string;
      }
    | {
          _id: string;
      }
)) => {
    const _id = '_id' in rest ? rest._id : null;
    const token = 'token' in rest ? rest.token : null;
    return new Promise<Ether.CaseManager.UnblockOrder>((resolve, reject) => {
        api.post<
            Ether.ApiResponse<
                (
                    | {
                          error: string;
                      }
                    | Ether.CaseManager.UnblockOrder
                )[]
            >
        >(
            `/accept-order`,
            {
                accepted,
                order_id: _id,
                order_type: 'unblock_order',
            },
            {
                params: {
                    token: token,
                },
            }
        )
            .then((res) => {
                const data = res.data.payload[0];
                if (!data) throw new Error('accept-order did not return data');
                if ('error' in data) throw new Error(data.error);
                resolve(data);
            })
            .catch((err) => reject(err));
    });
};
