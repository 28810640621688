import { useProject } from 'hooks/context/project/useProject';
import useListTag from 'hooks/queries/tag/useListTag';
import { SortOrder } from 'primereact/api';

/**
 * Hook that queries all target pirate brand tags. Prefer to use this instead of writing your query.
 * @returns 
 */
const useListPirateBrands = () => {
    const project = useProject();
    const pirateBrandsQuery = useListTag(
        {
            project_id: project._id,
            options: {
                limit: 0,
                sort: {
                    field: 'name',
                    order: SortOrder.ASC,
                },
                devFilters: {
                    category: 'pirate-brand',
                    model_type: 'target',
                },
            },
        },
    );
    return pirateBrandsQuery;
};

export default useListPirateBrands;