import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyUnblockOrder } from 'services/ether/case-manager/unblock-orders';
import { DetailUnblockOrderEP } from 'services/ether/case-manager/unblock-orders/types';

const useDetailManyUnblockOrder = (
    params: DetailUnblockOrderEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['unblock-order', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyUnblockOrder({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailManyUnblockOrder;
