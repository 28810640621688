import { Tooltip } from 'primereact/tooltip';

export type MenuItemProps = {
    id: string;
    label?: string;
    icon?: string;
    active?: boolean;
    onClick?: () => void;
    tooltip?: string | JSX.Element;
    disabled?: boolean;
};

const MenuItem: React.FC<MenuItemProps> = ({
    id,
    label,
    active,
    icon,
    onClick,
    tooltip,
    disabled,
}) => {
    const iconColor = active
        ? 'text-green-500'
        : disabled
        ? 'text-transparent-gray'
        : 'text-gray-blue-300';
    const textColor = active
        ? 'text-white'
        : disabled
        ? 'text-transparent-gray'
        : 'text-gray-blue-300';
    const bgColor = active ? '#D9D9D91A' : undefined;
    const sideBorder = active
        ? 'border-solid border-r-4 border-green-500 border-y-0 border-x-0'
        : undefined;

    const tooltipClassname = `${id}-tooltip-menuitem`;

    return (
        <div
            className={[
                tooltipClassname,
                'flex',
                'flex-row',
                'gap-4',
                'p-6',
                ...(disabled ? [] : ['hover:cursor-pointer']),
                sideBorder,
            ].join(' ')}
            style={{
                backgroundColor: bgColor,
            }}
            onClick={disabled ? undefined : onClick}
        >
            {icon ? <i className={[icon, iconColor].join(' ')} /> : null}
            <span className={textColor}>{label}</span>
            {tooltip && (
                <Tooltip target={'.' + tooltipClassname} position='right'>
                    {tooltip}
                </Tooltip>
            )}
            {/* <i className={['pi pi-chevron-right', textColor].join(' ')} /> */}
        </div>
    );
};

type SideVerticalMenuProps = {
    menuItems: MenuItemProps[];
    activeIndex?: number;
    activeId?: string;
    onChange?: (options: { id: string; index: number }) => void;
};

const SideVerticalMenu: React.FC<SideVerticalMenuProps> = ({
    menuItems,
    activeIndex,
    activeId,
    onChange,
}) => {
    return menuItems.map((t, i) =>
        t.label ? (
            <MenuItem
                id={t.id}
                key={t.id}
                label={t.label}
                active={i === activeIndex || t.id === activeId}
                onClick={
                    onChange
                        ? () =>
                              onChange({
                                  id: t.id,
                                  index: i,
                              })
                        : undefined
                }
                icon={t.icon}
                disabled={t.disabled}
                tooltip={t.tooltip}
            />
        ) : null
    );
};

export default SideVerticalMenu;
