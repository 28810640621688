import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import SelectManyItems from 'components/form/SelectItems/SelectManyItems';
import { SelectManyItemsProps } from 'components/form/SelectItems/types';
import { ListBlockOrderEP } from 'services/ether/case-manager/block-orders/types';
import useDetailBlockOrderList from 'hooks/queries/block-order/useDetailManyBlockOrderList';

const SelectManyBlockOrders: React.FC<
    SelectManyItemsProps<
        Ether.CaseManager.BlockOrder,
        ListBlockOrderEP.Filters.Options
    >
> = (props) => {
    const [localization] = useLocalization();
    const project = useProject();
    const query = useDetailBlockOrderList({
        project_id: project._id,
        options: {
            ...props.queryOptions,
            limit: 0,
        },
    });

    return (
        <SelectManyItems
            title={localization.models.blockOrder.plural}
            placeholder={
                localization.components.models.blockOrder.form
                    .selectManyPlaceholder
            }
            label={localization.models.blockOrder.plural}
            items={query.data?.payload}
            itemsLoading={query.isFetching}
            {...props}
        />
    );
};

export default SelectManyBlockOrders;
