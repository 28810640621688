import useQueryWithRefresh from 'hooks/helpers/useQueryWithRefresh/useQueryWithRefresh';
import { detailManyAuthorization } from 'services/ether/case-manager/authorizations';
import { DetailAuthorizationEP } from 'services/ether/case-manager/authorizations/types';

const useDetailAuthorization = (
    params: DetailAuthorizationEP.Params.Many,
    queryOptions?: {
        enabled?: boolean;
    }
) => {
    const queryKey = ['authorization', 'detail', params];

    const query = useQueryWithRefresh({
        queryKey,
        queryFn: ({ signal }) =>
            detailManyAuthorization({
                ...params,
                signal,
            }),
        ...queryOptions,
    });

    return query;
};

export default useDetailAuthorization;
