import DetailOperationModal from 'components/models/Operation/DetailOperationModal';
import { useProject } from 'hooks/context/project/useProject';
import useDetailOneOperation from 'hooks/queries/operation/useDetailOneOperation';
import { useMemo, useState } from 'react';

/**
 * Hook to control shown dialog.
 *
 * @returns object.dialog - The dialog dialog, should be provided within the children in the used component.
 * @returns object.show - Function to show the dialog.
 * @returns object.hide - Function to hide the dialog.
 */
const useViewOperationDetailDialog = () => {
    const project = useProject();
    const [selectedOperation, setSelectedOperation] = useState<string | null>(
        null
    );

    const operationQuery = useDetailOneOperation(
        {
            _id: selectedOperation ?? '',
            project_id: project._id,
        },
        {
            enabled: !!selectedOperation,
        }
    );

    const hideDialog = () => setSelectedOperation(null);
    const showDialog = (operationId: string) =>
        setSelectedOperation(operationId);

    const dialog = useMemo(() => {
        return selectedOperation ? (
            <DetailOperationModal
                visible
                onHide={hideDialog}
                operation={operationQuery.data}
            />
        ) : null;
    }, [operationQuery.data, selectedOperation]);

    return {
        dialog,
        show: showDialog,
        hide: hideDialog,
    };
};

export default useViewOperationDetailDialog;
