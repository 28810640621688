import { DetailOperationModalProps } from './types';
import { useLocalization } from 'hooks/context/useLocalization';
import { DateBadge } from 'components/ethercity-primereact';
import LoadingMessage from 'components/misc/LoadingMessage';
import DetailedModalWithMenu from 'components/misc/DetailedModalWithMenu';
import { GetTargetHistoryEP } from 'services/ether/case-manager/targets/types';
import { Button } from 'primereact/button';
import { useProject } from 'hooks/context/project/useProject';
import { getUserDisplayName } from 'utils/user';
import useDetailAuthorization from 'hooks/queries/authorization/useDetailAuthorization';
import useDetailBlockOrderHistory from 'hooks/queries/block-order/useDetailManyBlockOrderHistory';
import useDetailManyUnblockOrderHistory from 'hooks/queries/unblock-order/useDetailManyUnblockOrderHistory';
import { useMemo } from 'react';
import { Chart } from 'primereact/chart';
import OperationStatusBadge from '../OperationStatusBadge';

const OperationCharacteristicsBox: React.FC<{
    topText: string;
    value: number;
    bottomText?: string | null;
}> = ({ topText, value, bottomText }) => {
    return (
        <div className='flex flex-col gap-2 p-3 border border-solid border-gray-blue-300 rounded-md items-center'>
            <span className='text-gray-blue-300'>
                {topText.toLocaleUpperCase()}
            </span>
            <strong className='text-4xl'>
                {new Intl.NumberFormat().format(value)}
            </strong>
            <span className='text-sm text-gray-blue-300'>{bottomText}</span>
        </div>
    );
};

// TODO: Transform into a generic component
const LabelValuePair: React.FC<{
    label: string;
    value: string | JSX.Element;
    className?: string;
}> = ({ label, value, className }) => {
    const finalClass = ['flex flex-row gap-2 items-center', className].join(
        ' '
    );
    return (
        <div className={finalClass}>
            <span className='font-bold'>{label}: </span>
            <span>{value}</span>
        </div>
    );
};

// TODO: Transform into a generic component
const ModelEntries: React.FC<{
    header?: string;
    data: GetTargetHistoryEP.HistoryData[];
    route: 'authorizations' | 'block-orders' | 'unblock-orders';
}> = ({ header, data, route }) => {
    // TODO: LOCALIZE
    const [localization] = useLocalization();
    const entries = data.map((entry) => (
        <section
            key={entry._id}
            className='ml-8 mr-4 p-4 border border-gray-blue-300 border-solid rounded-md'
        >
            <div className='flex flex-row gap-2 items-center mb-4'>
                <b>{entry.name}</b>
                {
                    <a
                        href={'/' + route + '/' + entry._id}
                        rel='noreferrer noopener'
                        target='_blank'
                    >
                        <Button icon='pi pi-external-link' size='small' />
                    </a>
                }
            </div>
            <div className='grid grid-cols-4 gap-2'>
                <LabelValuePair
                    label={localization.fields.authorization.created}
                    value={<DateBadge value={entry.created_at} />}
                />
                <LabelValuePair
                    label={localization.fields.authorization.createdBy}
                    value={entry.responsible}
                    className='col-span-3'
                />
                <LabelValuePair
                    label={localization.fields.authorization.notified}
                    value={
                        entry.registered_at ? (
                            <DateBadge value={entry.registered_at} />
                        ) : (
                            '-'
                        )
                    }
                />
                {entry.authorization_flow_data && (
                    <LabelValuePair
                        label={localization.models.authorizationFlow.singular}
                        className='col-span-3'
                        value={entry.authorization_flow_data.name}
                    />
                )}
                {entry.authorization_config_data && (
                    <LabelValuePair
                        className='col-span-4'
                        label={localization.models.authorizationConfig.singular}
                        value={entry.authorization_config_data.name}
                    />
                )}
                {entry.authorization_configs_data && (
                    <LabelValuePair
                        className='col-span-4'
                        label={localization.models.authorizationConfig.plural}
                        value={entry.authorization_configs_data
                            .map((c) => c.name)
                            .join(', ')}
                    />
                )}
            </div>
        </section>
    ));

    return (
        <div key={route} className='flex flex-col gap-2'>
            <h2>{header}</h2>
            <div className='flex flex-col gap-10'>{entries}</div>
        </div>
    );
};

const DetailOperationHistory: React.FC<{
    operation: Ether.CaseManager.Operation.Detailed;
}> = ({ operation }) => {
    const [localization] = useLocalization();
    const thisLocalization =
        localization.components.models.operation.views.detailModal;
    const project = useProject();

    const options = {
        project_id: project._id,
        options: {
            limit: 0,
            devFilters: {
                operation_id: operation._id,
            },
        },
    };

    const authorizationsQuery = useDetailAuthorization(options);
    const blockOrdersQuery = useDetailBlockOrderHistory(options);
    const unblockOrdersQuery = useDetailManyUnblockOrderHistory(options);

    const isLoading =
        authorizationsQuery.isLoading || blockOrdersQuery.isLoading;

    const history = [
        ...(authorizationsQuery.data &&
        authorizationsQuery.data?.payload?.length > 0
            ? [
                  <ModelEntries
                      header={localization.models.authorization.plural}
                      route='authorizations'
                      data={authorizationsQuery.data.payload.map((a) => ({
                          _id: a._id,
                          created_at: a.created_at,
                          name: a.name,
                          registered_at: a.registered_at ?? null,
                          responsible: getUserDisplayName(
                              a.created_by_data?.[0]
                          ),
                          authorization_config_data: {
                              _id: a.authorization_configs_data?.[0]?._id ?? '',
                              name:
                                  a.authorization_configs_data?.[0]?.name ?? '',
                          },
                          authorization_flow_data: {
                              _id: a.authorization_flows_data?.[0]?._id ?? '',
                              name: a.authorization_flows_data?.[0]?.name ?? '',
                          },
                      }))}
                  />,
              ]
            : []),
        ...(blockOrdersQuery.data && blockOrdersQuery.data?.payload?.length > 0
            ? [
                  <ModelEntries
                      header={localization.models.blockOrder.plural}
                      route='block-orders'
                      data={blockOrdersQuery.data.payload.map((b) => ({
                          _id: b._id,
                          created_at: b.created_at,
                          name: b.name,
                          registered_at: b.registered_at ?? null,
                          responsible: getUserDisplayName(
                              b.created_by_data?.[0]
                          ),
                          authorization_configs_data:
                              b.authorization_configs_data?.map((c) => ({
                                  _id: c._id,
                                  name: c.name,
                              })),
                      }))}
                  />,
              ]
            : []),
        ...(unblockOrdersQuery.data &&
        unblockOrdersQuery.data?.payload?.length > 0
            ? [
                  <ModelEntries
                      header={localization.models.unblockOrder.plural}
                      route='unblock-orders'
                      data={unblockOrdersQuery.data.payload.map((b) => ({
                          _id: b._id,
                          created_at: b.created_at,
                          name: b.name,
                          registered_at: b.registered_at ?? null,
                          responsible: getUserDisplayName(
                              b.created_by_data?.[0]
                          ),
                      }))}
                  />,
              ]
            : []),
    ];

    return (
        <div className='flex flex-col gap-4 grow-0'>
            <h2>{thisLocalization.tabSummary.header}</h2>
            {isLoading && (
                <LoadingMessage>{localization.common.loading}</LoadingMessage>
            )}
            {!isLoading && (!history || history.length <= 0) && (
                <h3>{localization.common.unavailable}</h3>
            )}
            {!isLoading && history}
        </div>
    );
};

const DoughnutChart: React.FC<{ data: object }> = ({ data }) => {
    const options = {
        plugins: {
            legend: {
                display: false,
                position: 'bottom',
                labels: {
                    color: '#FFF',
                },
            },
        },
        cutout: '80%',
        borderWidth: 1,
        spacing: 0,
        radius: '80%',
    };
    return (
        <Chart
            type='doughnut'
            data={data}
            options={options}
            className='h-full z-10'
        />
    );
};

const ChartLegend: React.FC<{ data: object }> = ({ data }) => {
    const options = {
        plugins: {
            legend: {
                labels: {
                    color: '#FFF',
                },
            },
            tooltip: {
                enabled: false, // Disable tooltips
            },
        },
        radius: 0,
        weight: 0,
        borderWidth: 0,
    };
    return (
        <div className='h-32 relative overflow-hidden'>
            <Chart
                type='doughnut'
                data={data}
                options={options}
                className='w-full absolute top-0'
            />
        </div>
    );
};

const ChartOrganizer: React.FC<{
    data: object;
    centerValue: number;
    topText: string;
    bottomText: string;
}> = ({ data, centerValue, topText, bottomText }) => {
    if (centerValue === 0) return <span />

    return (
        <div className='w-full'>
            <div className='text-gray-blue-300 mb-4 text-center'>
                {topText.toLocaleUpperCase()}
            </div>
            <div className='w-full h-72 relative flex justify-center'>
                <div className='flex flex-col justify-center items-center absolute w-[30%] h-full pb-2'>
                    <strong className='text-4xl'>
                        {new Intl.NumberFormat().format(centerValue)}
                    </strong>
                    <span className='text-sm text-center text-gray-blue-300'>
                        {bottomText.toLocaleUpperCase()}
                    </span>
                </div>
                <DoughnutChart data={data} />
            </div>
            <ChartLegend data={data} />
        </div>
    );
};

const DetailOperationGeneral: React.FC<{
    operation: Ether.CaseManager.Operation.Detailed;
}> = ({ operation }) => {
    const [localization] = useLocalization();
    const project = useProject();
    const thisLocalization =
        localization.components.models.operation.views.detailModal;

    const data: [string, string | JSX.Element][] = [];

    const breadCrumbTitle = `${project.name} / ${localization.models.operation.plural}`;

    data.push([
        localization.fields.operation.created,
        <DateBadge value={operation.created_at} />,
    ]);

    if (operation.start_date)
        data.push([
            thisLocalization.tabGeneral.fields.start,
            <DateBadge value={operation.start_date} />,
        ]);

    if (operation.end_date)
        data.push([
            thisLocalization.tabGeneral.fields.end,
            <DateBadge value={operation.end_date} />,
        ]);

    const blockOrderBottomText = useMemo(() => {
        let judCount = 0;
        let admCount = 0;
        operation.order_type_count?.forEach((orderType) => {
            if (orderType.type === 'judicial') judCount = orderType.count;
            if (orderType.type === 'administrative') admCount = orderType.count;
        });
        return `Jud: ${judCount} / Adm: ${admCount}`;
    }, [operation]);

    const targetBottomText = useMemo(() => {
        return `IP: ${operation.ips_count} / Domain: ${operation.domains_count}`;
    }, [operation]);

    const targetChartData = useMemo(() => {
        return {
            labels: ['IP', 'Domain'],
            datasets: [
                {
                    data: [operation.ips_count, operation.domains_count],
                },
            ],
        };
    }, [operation]);

    const hostingChartData = useMemo(() => {
        const labelsAndData: Record<string, number> = {};
        operation.all_hosting_companies?.forEach((h) => {
            if (!(h in labelsAndData)) labelsAndData[h] = 0;
            labelsAndData[h] = (labelsAndData[h] ?? 0) + 1;
        });
        const labels: string[] = [];
        const data: number[] = [];
        const entries = Object.entries(labelsAndData).sort((c1, c2) =>
            c1[1] > c2[1] ? -1 : c1[1] < c2[1] ? 1 : 0
        );
        const top5 = entries.slice(0, 5);
        const remainder = entries.slice(5);
        top5.forEach(([company, count]) => {
            labels.push(company);
            data.push(count);
        });
        labels.push(localization.common.others);
        data.push(
            remainder.reduce((ṕrevious, curr) => {
                return ṕrevious + curr[1];
            }, 0)
        );
        return {
            labels,
            datasets: [{ data }],
        };
    }, [operation, localization]);

    const complaintsChartData = useMemo(() => {
        const labels: string[] = [];
        const data: number[] = [];
        const entries = operation.company_authorization_count;
        entries?.forEach(({ count, type }) => {
            labels.push(type);
            data.push(count);
        });
        return {
            labels,
            datasets: [{ data }],
        };
    }, [operation]);

    const charts = useMemo(
        () => [
            <ChartOrganizer
                key='targets-chart'
                data={targetChartData}
                topText={thisLocalization.tabGeneral.charts.targetType}
                centerValue={operation.targets_count}
                bottomText={localization.models.target.plural}
            />,
            <ChartOrganizer
                key='hostings-chart'
                data={hostingChartData}
                topText={thisLocalization.tabGeneral.charts.hostingCompanies}
                centerValue={operation.all_hosting_companies?.length ?? 0}
                bottomText={
                    localization.models.hostingCompany.abbreviatedPlural
                }
            />,
            <ChartOrganizer
                key='complaints-chart'
                data={complaintsChartData}
                topText={thisLocalization.tabGeneral.charts.complaintsOrg}
                centerValue={operation.authorizations_count}
                bottomText={localization.models.authorization.plural}
            />,
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [localization, targetChartData, complaintsChartData, hostingChartData]
    );

    return (
        <section>
            <p className='text-gray-blue-300'>{breadCrumbTitle}</p>
            <OperationStatusBadge item={operation} />
            <h2 className='text-4xl'>{operation.name}</h2>
            <div className='grid grid-cols-3 gap-4 text-gray-blue-300'>
                {data.map(([i, j]) => (
                    <LabelValuePair label={i} value={j} key={i} />
                ))}
            </div>
            <section className='mt-16'>
                <h2 className='text-gray-blue-300'>
                    {thisLocalization.tabGeneral.operationCount.header}
                </h2>
                <div className='grid grid-cols-3 gap-2 mt-8'>
                    <OperationCharacteristicsBox
                        topText={localization.models.blockOrder.plural}
                        value={operation.block_orders_count}
                        bottomText={blockOrderBottomText}
                    />
                    <OperationCharacteristicsBox
                        topText={localization.models.authorization.plural}
                        value={operation.authorizations_count}
                    />
                    <OperationCharacteristicsBox
                        topText={localization.models.target.plural}
                        value={operation.targets_count}
                        bottomText={targetBottomText}
                    />
                </div>
            </section>
            <div className='grid grid-cols-3 gap-2 mt-12'>{charts}</div>
        </section>
    );
};

const DetailOperationModal: React.FC<DetailOperationModalProps> = ({
    operation,
    ...props
}) => {
    const [localization] = useLocalization();
    const thisLocalization =
        localization.components.models.operation.views.detailModal;

    const tabOptions = operation
        ? [
              {
                  id: 'general',
                  icon: 'pi pi-home',
                  label: thisLocalization.tabGeneral.title,
                  element: () => (
                      <DetailOperationGeneral operation={operation} />
                  ),
              },
              {
                  id: 'summary',
                  icon: 'pi pi-info-circle',
                  label: thisLocalization.tabSummary.title,
                  element: () => (
                      <DetailOperationHistory operation={operation} />
                  ),
              },
          ]
        : [];

    let title = operation?.name
        ? `${thisLocalization.title} - ${operation.name}`
        : thisLocalization.title;

    return (
        <DetailedModalWithMenu
            menuItems={tabOptions}
            overrideView={
                !operation ? (
                    <LoadingMessage>
                        {localization.common.loading}
                    </LoadingMessage>
                ) : undefined
            }
            {...props}
            header={title}
        />
    );
};

export default DetailOperationModal;
