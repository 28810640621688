import { useAuth } from 'hooks/context/useAuth';
import { Button } from 'primereact/button';
import { DateBadge } from 'components/ethercity-primereact';
import { Link } from 'react-router-dom';
import { useProjectMaybe } from 'hooks/context/project/useProject';
import TargetsDataTable from 'components/models/Targets/TargetsDataTable';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/user';
import CellGroup from 'components/display/CellGroup';
import useExportTarget from 'hooks/queries/target/useExportTarget';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import LoadingMessage from 'components/misc/LoadingMessage';
import GoBackButton from 'components/misc/GoBackButton';
import { isBlockOrderRespondable } from 'utils/blockOrder';
import useExportBlockOrderFiles from 'hooks/queries/block-order/useExportBlockOrderFiles';
import { useBlockOrderRespond } from 'hooks/models/blockOrder/useBlockOrderRespond';
import BlockOrderStatusBadge from '../BlockOrderStatusBadge';
import AuthorizationDataTable from 'components/models/Authorization/AuthorizationDataTable';
import { detailManyAuthorization } from 'services/ether/case-manager/authorizations';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import BlockOrderHistoryViewer from 'components/display/BlockOrderHistoryViewer';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import ShowOperation from 'components/models/Operation/ShowOperation';
import useUpsertBlockOrderDialog from 'hooks/models/blockOrder/useUpsertBlockOrderDialog';
import { detailOperatorCompany } from 'services/ether/case-manager/company';
import CompanyDataTable from 'components/models/Company/CompanyDataTable';
import useUpdateOperationDialog from 'hooks/dialogs/operation/useUpdateOperationDialog';
import useShowBlockOrderStats from 'hooks/dialogs/blockOrder/useShowBlockOrderStats';

const DetailedBlockOrderView: React.FC<{
    blockOrder?: Ether.CaseManager.BlockOrder.Detailed | null;
    isLoading: boolean;
    error: Error | null;

    hideBackButton?: boolean;
    hideViewAllBlockOrderButton?: boolean;

    definedProject?: Ether.CaseManager.Project;
    userId: string | null;
    operatorId: string | null;
    userRole?: Ether.CaseManager.Role;
}> = ({
    blockOrder,
    isLoading,
    error,

    definedProject,
    userRole,
    userId,

    hideBackButton,
    hideViewAllBlockOrderButton,
}) => {
    const { user, permissions } = useAuth();
    const [localization] = useLocalization();

    const projectContext = useProjectMaybe();
    const project = definedProject ?? projectContext;
    if (!project) throw new Error('missing project');

    const { UpsertDialog: UpsertBlockDialog, showEdit: showEditBlock } =
        useUpsertBlockOrderDialog();
    const { dialog: statsDialog, show: showStats } = useShowBlockOrderStats();

    const {
        filters: targetFilters,
        setFilters: setTargetFilters,
        sort: targetSort,
        setSort: setTargetSort,
    } = useInitDataTableState();

    const { exportFile: exportTargets, isExporting: isExportingTargets } =
        useExportTarget();
    const { exportFile: exportBlockOrderFiles, isExporting: isExportingFiles } =
        useExportBlockOrderFiles(blockOrder?._id ?? '');

    const [respondBlockOrder, respondStatus] = useBlockOrderRespond();

    const { dialog: updateOperationDialog, show: showUpdateOperation } =
        useUpdateOperationDialog();

    if (!userRole) userRole = user.role;
    if (!userId) userId = user.data?._id ?? null;
    if (!userId) throw new Error('no user');

    if (isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (error) return <h2>{localization.validations.generic.unhandled}</h2>;
    if (!blockOrder)
        return <h2>{localization.validations.generic.notFound}</h2>;

    const authorizationConfigs = blockOrder.authorization_configs_data;
    const authorizationFlow =
        blockOrder.authorizations_data?.[0]?.authorization_flows_data?.[0]
            ?.name;

    const isRespondable = isBlockOrderRespondable({
        permissions: permissions,
        blockOrder: blockOrder,
        userId,
    });
    const isEditable =
        blockOrder.status === 'draft' && permissions.insertBlockOrders;

    const blockOrderViews: TableWithTabView[] = [];

    if (permissions.viewAuthorizations)
        blockOrderViews.push({
            label: localization.models.authorization.plural,
            fetchData: (params) =>
                detailManyAuthorization({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            block_order_id: blockOrder._id,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount:
                blockOrder.total_authorizations ??
                blockOrder.authorizations_data?.length ??
                -1,
            tableElement: (props) => (
                <AuthorizationDataTable showApproved {...props} />
            ),
            model: 'authorization',
        });

    if (permissions.viewTargets) {
        blockOrderViews.push({
            label: localization.models.target.plural,
            fetchData: (params) =>
                detailManyTargets({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            block_order_id: blockOrder._id,
                            get_evidence_parsed: false,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount: blockOrder.total_targets ?? -1,
            tableElement: (props) => (
                <TargetsDataTable
                    projectId={project._id}
                    showTags
                    refBlockOrder={blockOrder}
                    {...props}
                    setFilters={(f) => {
                        setTargetFilters(f);
                        if (props.setFilters) props.setFilters(f);
                    }}
                    onSort={(s) => {
                        setTargetSort({
                            field: s.sortField,
                            order: s.sortOrder,
                        });
                        if (props.onSort) props.onSort(s);
                    }}
                />
            ),
            model: 'targets',
        });
        if (blockOrder.total_targets_review !== 0)
            blockOrderViews.push({
                label: localization.models.target.inReviewTargets,
                fetchData: (params) =>
                    detailManyTargets({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                block_order_id: blockOrder._id,
                                get_evidence_parsed: false,
                                safe_ip: false,
                            },
                        },
                        signal: params.signal,
                    }),
                totalCount: blockOrder.total_targets_review ?? -1,
                tableElement: (props) => (
                    <TargetsDataTable
                        projectId={project._id}
                        showTags
                        refBlockOrder={blockOrder}
                        {...props}
                        setFilters={(f) => {
                            setTargetFilters(f);
                            if (props.setFilters) props.setFilters(f);
                        }}
                        onSort={(s) => {
                            setTargetSort({
                                field: s.sortField,
                                order: s.sortOrder,
                            });
                            if (props.onSort) props.onSort(s);
                        }}
                    />
                ),
                model: 'targets',
            });
    }

    if (permissions.detailOperatorCompany)
        blockOrderViews.push({
            label: localization.models.operator.plural,
            fetchData: (params) =>
                detailOperatorCompany({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            block_order_id: blockOrder._id,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount: blockOrder.total_operators ?? 0,
            tableElement: (props) => (
                <CompanyDataTable
                    {...props}
                    showOperatorCount
                    blockOrder={blockOrder}
                    showExpand='target'
                />
            ),
            overrideFilters: {},
            model: 'company',
        });

    if (permissions.viewDocuments)
        blockOrderViews.push({
            label: localization.models.document.plural,
            fetchData: (params) =>
                detailManyDocuments({
                    project_id: project._id,
                    options: {
                        ...params.options,
                        devFilters: {
                            block_order_id: blockOrder._id,
                        },
                    },
                    signal: params.signal,
                }),
            totalCount: blockOrder.total_documents ?? -1,
            tableElement: (props) => <DocumentDataTable {...props} />,
            model: 'document',
        });
    if (permissions.viewBlockHistory)
        blockOrderViews.push({
            label: localization.components.models.blockOrder.views.history
                .title,
            customElement: (
                <BlockOrderHistoryViewer blockOrderId={blockOrder._id} />
            ),
        });

    return (
        <section className='w-full'>
            {updateOperationDialog}
            {statsDialog}
            <UpsertBlockDialog />
            <div className='flex mt-2'>
                {!hideBackButton && <GoBackButton />}
                <div className='flex ml-auto gap-2'>
                    {isRespondable && (
                        <>
                            <Button
                                icon='pi pi-check'
                                onClick={() =>
                                    respondBlockOrder(blockOrder, true)
                                }
                                severity='success'
                                label={
                                    localization.components.common.button
                                        .confirm
                                }
                                disabled={respondStatus.isLoading}
                            />
                            <Button
                                icon='pi pi-times'
                                severity='danger'
                                label={
                                    localization.components.common.button.reject
                                }
                                disabled
                            />
                            <Button
                                icon='pi pi-question-circle'
                                severity='warning'
                                label={
                                    localization.components.common.button
                                        .contest
                                }
                                disabled
                            />
                        </>
                    )}
                    {isEditable && (
                        <Button
                            onClick={() => showEditBlock(blockOrder._id)}
                            icon='pi pi-pencil'
                            label={localization.components.common.button.edit}
                        />
                    )}
                    {permissions.getBlockOrderStats && (
                        <Button
                            icon='pi pi-chart-bar'
                            label={localization.components.common.stats.header}
                            onClick={() =>
                                showStats({
                                    id: blockOrder._id,
                                    name: blockOrder.name,
                                })
                            }
                        />
                    )}
                    {permissions.updateOperationOrder && (
                        <Button
                            label={
                                localization.components.models.operation.views
                                    .updateOperation.title
                            }
                            onClick={() =>
                                showUpdateOperation({
                                    data: {
                                        order_id: blockOrder._id,
                                        order_type: 'block_order',
                                    },
                                    startingOperationId:
                                        blockOrder.operation_id ?? null,
                                })
                            }
                        />
                    )}
                    {!hideViewAllBlockOrderButton && (
                        <Link to='..'>
                            <Button
                                label={
                                    localization.components.models.blockOrder
                                        .button.viewAll
                                }
                            />
                        </Link>
                    )}
                    {permissions.exportTargets && (
                        <Button
                            icon='pi pi-download'
                            onClick={() =>
                                exportTargets({
                                    params: {
                                        fields: {
                                            blockOrderId: blockOrder._id,
                                        },
                                        options: {
                                            rawFilters: targetFilters,
                                            sort: targetSort,
                                        },
                                    },
                                })
                            }
                            label={
                                localization.components.models.target.button
                                    .export
                            }
                            loading={isExportingTargets}
                        />
                    )}
                    {permissions.exportBlockOrderFiles && (
                        <Button
                            icon='pi pi-download'
                            onClick={exportBlockOrderFiles}
                            label={
                                localization.components.common.button
                                    .exportFiles
                            }
                            loading={isExportingFiles}
                        />
                    )}
                </div>
            </div>
            <section className='grid grid-cols-3 gap-16 items-start my-5'>
                <div>
                    <span>{project.name}</span>
                    <h2 className='mt-1'>{blockOrder.name}</h2>
                    <ShowOperation operation={blockOrder.operation_data} />
                    {/* <div style={{ display: 'flex', gap: '8px' }}>
                        {blockOrder.tags.map((t) => (
                            <Badge
                                key={`${t.category}/${t.name}`}
                                value={
                                    t.name
                                        ? `${t.category}/${t.name}`
                                        : t.category
                                }
                            />
                        ))}
                    </div> */}
                    <div className='flex flex-row gap-8'>
                        <div>
                            <span>
                                {localization.models.authorizationFlow.singular}
                            </span>
                            <h3 className='mt-1'>{authorizationFlow}</h3>
                        </div>
                        <div>
                            <span>
                                {localization.models.authorizationConfig.plural}
                            </span>
                            <h3 className='mt-1'>
                                {authorizationConfigs
                                    ?.map((c) => c.name)
                                    ?.join(', ')}
                            </h3>
                        </div>
                    </div>
                    <div className='grid grid-cols-3 gap-4 text-sm font-bold mt-4'>
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.status}:
                                </span>
                            }
                            element2={
                                <BlockOrderStatusBadge
                                    blockOrder={blockOrder}
                                />
                            }
                            span={3}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.created}:
                                </span>
                            }
                            element2={
                                <DateBadge value={blockOrder.created_at} />
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.blockOrder.createdBy}:
                                </span>
                            }
                            element2={
                                <span>
                                    {getUserDisplayName(
                                        blockOrder.created_by_data?.[0]
                                    )}
                                </span>
                            }
                            span={2}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.blockOrder
                                            .registeredAt
                                    }
                                    :
                                </span>
                            }
                            element2={
                                blockOrder.registered_at ? (
                                    <DateBadge
                                        value={blockOrder.registered_at}
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                    </div>
                </div>
            </section>
            <ViewTablesWithTabs
                mainModel='block-order'
                onTabChange={() => setTargetFilters({})}
                uniqueQueryKey={blockOrder._id}
                views={blockOrderViews}
            />
        </section>
    );
};

export default DetailedBlockOrderView;
