import {
    mapDevFilters,
    parseDataTableFilterMetaForAPI,
} from 'services/ether/utils';
import {
    UpsertOperationEP,
    DetailOperationEP,
    GetOperationStatsEP,
    UpdateOperationOrderEP,
    UpdateUserDefaultOperationEP,
    UpdateOperationAuthorizationEP,
} from './types';
import { getApiBase, listBase, postApiBase } from 'services/ether/base';
import _ from 'lodash';

export const _detailOperations = ({
    project_id,
    options,
    signal,
}: DetailOperationEP.Params.Restricted) => {
    const devFilters = options?.devFilters ?? {};
    const devKeys: DetailOperationEP.Filters.Map = {
        _id: ['_id', devFilters._id],
        additional_fields: [
            'additional_fields',
            devFilters.additional_fields?.join(','),
        ],
    };
    const mappedFilters = mapDevFilters(devKeys);
    const filters = _.merge(
        {
            project_id,
        },
        options?.rawFilters,
        mappedFilters
    );
    return listBase<Ether.CaseManager.Operation.Detailed[]>({
        endpoint: '/detail-operation',
        handleParams: parseDataTableFilterMetaForAPI,
        options: {
            ...options,
            filters,
        },
        signal,
    });
};

export const detailOneOperation = async ({
    _id,
    project_id,
    signal,
}: DetailOperationEP.Params.One) => {
    const { payload } = await _detailOperations({
        project_id,
        options: {
            limit: 1,
            rawFilters: {
                _id,
            },
            devFilters: {
                additional_fields: [
                    'all_hosting_companies',
                    'order_type_count',
                    'company_authorization_count',
                ],
            },
        },
        signal,
    });
    return payload[0] ?? null;
};

export const detailManyOperations = async ({
    project_id,
    options,
    signal,
}: DetailOperationEP.Params.Many) => {
    return _detailOperations({
        project_id,
        options,
        signal,
    });
};

export const upsertOperation = (data: UpsertOperationEP.Data) => {
    const postData: UpsertOperationEP.PostData = {
        ...data,
        start_date: data.start_date?.toISOString(),
        end_date: data.end_date?.toISOString(),
    };
    return postApiBase<UpsertOperationEP.Result>({
        endpoint: '/upsert-operation',
        data: postData,
    });
};

export const updateUserDefaultOperation = (
    data: UpdateUserDefaultOperationEP.Data
) => {
    return postApiBase<UpdateUserDefaultOperationEP.Result>({
        endpoint: '/update-user-default-operation',
        data: data,
    });
};

export const updateOperationOrder = async (
    data: UpdateOperationOrderEP.Data
) => {
    const result = await postApiBase<UpdateOperationOrderEP.Result>({
        endpoint: '/update-operation-order',
        data: data,
    });
    if (!result) throw new Error('order update-operation failed');
    return result;
};

export const updateOperationAuthorization = async (
    data: UpdateOperationAuthorizationEP.Data
) => {
    const result = await postApiBase<UpdateOperationAuthorizationEP.Result>({
        endpoint: '/update-operation-authorization',
        data: data,
    });
    if (!result) throw new Error('authorzation update-operation failed');
    return result;
};

export const getOperationStats = (options: {
    params: GetOperationStatsEP.Params;
    signal?: AbortSignal;
}) => {
    return getApiBase<GetOperationStatsEP.Result>({
        endpoint: '/get-operation-stats/' + options.params.operation_id,
        signal: options.signal,
    });
};
