import { useProject } from 'hooks/context/project/useProject';
import { useLocalization } from 'hooks/context/useLocalization';
import useListTag from 'hooks/queries/tag/useListTag';
import { SortOrder } from 'primereact/api';

/**
 * Hook that queries all authorization flags tags. Prefer to use this instead of writing your query.
 * @returns
 */
const useListFlags = () => {
    const [localization] = useLocalization();
    const project = useProject();

    const flagKeys = localization.components.models.tag.types.flag.values;

    const pirateBrandsQuery = useListTag({
        project_id: project._id,
        options: {
            limit: 0,
            sort: {
                field: 'name',
                order: SortOrder.ASC,
            },
            devFilters: {
                category: 'flag',
                model_type: 'authorization',
            },
        },
    });
    return {
        ...pirateBrandsQuery,
        data: pirateBrandsQuery.data
            ? {
                  ...pirateBrandsQuery.data,
                  payload: pirateBrandsQuery.data.payload.map((t) => ({
                      ...t,
                      label:
                          flagKeys?.[t.name as keyof typeof flagKeys] ?? t.name,
                  })),
              }
            : undefined,
    };
};

export default useListFlags;
