import { useProject } from 'hooks/context/project/useProject';
import useListTag from 'hooks/queries/tag/useListTag';
import { SortOrder } from 'primereact/api';

/**
 * Hook that queries authorization countries tags. Prefer to use this instead of writing your query.
 * @returns
 */
const useListCountries = () => {
    const project = useProject();

    const pirateBrandsQuery = useListTag({
        project_id: project._id,
        options: {
            limit: 0,
            sort: {
                field: 'name',
                order: SortOrder.ASC,
            },
            devFilters: {
                category: 'country',
                model_type: 'authorization',
            },
        },
    });
    return pirateBrandsQuery;
};

export default useListCountries;
