import { useMemo } from 'react';
import { useAuth } from 'hooks/context/useAuth';
import AuthorizationStatusBadge from '../AuthorizationStatusBadge';
import { DateBadge } from 'components/ethercity-primereact';
import { isAuthorizationRespondable } from 'utils/authorization';
import { useProject } from 'hooks/context/project/useProject';
import TargetsDataTable, {
    targetsFilters,
} from 'components/models/Targets/TargetsDataTable';
import { useLocalization } from 'hooks/context/useLocalization';
import { getUserDisplayName } from 'utils/user';
import CellGroup from 'components/display/CellGroup';
import ViewTablesWithTabs from 'components/display/ViewTablesWithTabs';
import { detailManyTargets } from 'services/ether/case-manager/targets';
import LoadingMessage from 'components/misc/LoadingMessage';
import { detailManyEvidences } from 'services/ether/case-manager/evidences';
import EvidencesDatatable from 'components/models/Evidences/EvidencesDataTable';
import { detailManyDocuments } from 'services/ether/case-manager/documents';
import DocumentDataTable from 'components/models/Document/DocumentDataTable';
import useInitDataTableState from 'hooks/helpers/useInitDataTableState';
import { ProgressBar } from 'primereact/progressbar';
import { TableWithTabView } from 'components/display/ViewTablesWithTabs/types';
import ShowDocumentsToSign from '../ShowDocumentsToSign';
import ShowOperation from 'components/models/Operation/ShowOperation';
import { FilterMatchMode } from 'primereact/api';
import { getFilterData } from 'utils/datatable';
import AuthorizationViewButtons from './components/AuthorizationViewButtons';

const DetailedAuthorizationView: React.FC<{
    authorization?: Ether.CaseManager.Authorization.Detailed | null;
    isLoading: boolean;
    error: Error | null;
    hideBackButton?: boolean;
    hideViewAllAuthorizationButton?: boolean;
}> = ({
    authorization,
    isLoading,
    error,

    hideBackButton,
    hideViewAllAuthorizationButton,
}) => {
    const { user, permissions, temporaryToken } = useAuth();
    const [localization] = useLocalization();

    const project = useProject();

    const {
        filters: targetFilters,
        setFilters: setTargetFilters,
        sort: targetSort,
        setSort: setTargetSort,
    } = useInitDataTableState();

    const userRole = user.role;
    const userId = user.data?._id;

    const canViewRejected = userRole !== 'authorizer-strict';
    const canViewPreRejected = userRole !== 'authorizer-strict';
    const canViewEvidences = true;

    const authViews: TableWithTabView[] = useMemo(() => {
        if (!authorization) return [];
        return [
            {
                label: localization.models.target.plural,
                fetchData: (params) =>
                    detailManyTargets({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                get_evidence_parsed: false,
                                authorization_ids: authorization._id,
                            },
                        },
                        signal: params.signal,
                    }),
                totalCount: authorization.count_data.targets_count,
                tableElement: (props) => (
                    <TargetsDataTable
                        projectId={project._id}
                        showTags
                        refAuthorization={authorization}
                        {...props}
                        setFilters={(f) => {
                            setTargetFilters(f);
                            if (props.setFilters) props.setFilters(f);
                        }}
                        onSort={(s) => {
                            setTargetSort({
                                field: s.sortField,
                                order: s.sortOrder,
                            });
                            if (props.onSort) props.onSort(s);
                        }}
                    />
                ),
                model: 'targets',
            },
            ...(canViewRejected
                ? [
                      {
                          label: localization.models.target
                              .manuallyRejectedTargets,
                          fetchData: (params) =>
                              detailManyTargets({
                                  project_id: project._id,
                                  options: {
                                      ...params.options,
                                      devFilters: {
                                          removed_authorization_id:
                                              authorization._id,
                                          pre_reprove: false,
                                          get_evidence_parsed: false,
                                      },
                                  },
                                  signal: params.signal,
                              }),
                          totalCount:
                              authorization.count_data
                                  .manual_rejected_targets_count,
                          tableElement: (props) => (
                              <TargetsDataTable
                                  projectId={project._id}
                                  showTags
                                  refAuthorization={authorization}
                                  {...props}
                              />
                          ),
                          model: 'targets',
                      } as TableWithTabView,
                  ]
                : []),
            ...(canViewPreRejected
                ? [
                      {
                          label: localization.models.target.autoRejectedTargets,
                          fetchData: (params) =>
                              detailManyTargets({
                                  project_id: project._id,
                                  options: {
                                      ...params.options,
                                      devFilters: {
                                          removed_authorization_id:
                                              authorization._id,
                                          pre_reprove: true,
                                          get_evidence_parsed: false,
                                      },
                                  },
                                  signal: params.signal,
                              }),
                          totalCount:
                              authorization.count_data
                                  .pre_rejected_targets_count,
                          tableElement: (props) => (
                              <TargetsDataTable
                                  projectId={project._id}
                                  showTags
                                  refAuthorization={authorization}
                                  {...props}
                              />
                          ),
                          model: 'targets',
                          overrideFilters: {
                              ...targetsFilters,
                              omit_pre_reprove: getFilterData(
                                  FilterMatchMode.NOT_CONTAINS,
                                  [
                                      'same_operation',
                                      'recently_notified',
                                      'unreversible',
                                  ]
                              ),
                          },
                      } as TableWithTabView,
                  ]
                : []),
            ...(canViewEvidences
                ? [
                      {
                          label: localization.models.evidence.plural,
                          fetchData: (params) =>
                              detailManyEvidences({
                                  project_id: project._id,
                                  options: {
                                      ...params.options,
                                      devFilters: {
                                          authorization_id: authorization._id,
                                          deduplicate: true,
                                      },
                                  },
                                  signal: params.signal,
                              }),
                          totalCount: authorization.count_data.evidences_count,
                          tableElement: (props) => (
                              <EvidencesDatatable {...props} />
                          ),
                          model: 'evidences',
                      } as TableWithTabView,
                  ]
                : []),
            {
                label: localization.models.document.plural,
                fetchData: (params) =>
                    detailManyDocuments({
                        project_id: project._id,
                        options: {
                            ...params.options,
                            devFilters: {
                                authorization_id: authorization._id,
                            },
                        },
                        signal: params.signal,
                    }),
                totalCount: authorization.count_data.documents_count,
                tableElement: (props) => <DocumentDataTable {...props} />,
                model: 'document',
            },
        ];
    }, [
        authorization,
        localization,
        canViewRejected,
        canViewPreRejected,
        canViewEvidences,
        project._id,
        setTargetFilters,
        setTargetSort,
    ]);

    const tabViews = useMemo(
        () => (
            <ViewTablesWithTabs
                mainModel='authorization'
                onTabChange={() => setTargetFilters({})}
                uniqueQueryKey={authorization?._id ?? ''}
                views={authViews}
            />
        ),
        [authViews, authorization?._id, setTargetFilters]
    );

    if (isLoading)
        return <LoadingMessage>{localization.common.loading}</LoadingMessage>;
    if (error) return <h2>{localization.validations.generic.unhandled}</h2>;
    if (!authorization)
        return <h2>{localization.validations.generic.notFound}</h2>;

    const authorizationConfig = authorization.authorization_configs_data?.[0];
    const authorizationFlow = authorization.authorization_flows_data?.[0];

    const isRespondable = isAuthorizationRespondable({
        permissions: permissions,
        authorization: authorization,
        userId,
        userRole,
    });
    const canSendForApproval =
        permissions.insertAuthorizations && authorization.status === 'draft';

    const isSignable = !!authorization._user_fields?.can_send_document;

    const targetEnrichmentComplete =
        authorization.count_data.targets_processed_count != null &&
        authorization.count_data.targets_count != null &&
        authorization.count_data.targets_processed_count ===
            authorization.count_data.targets_count;

    const targetsLeftToValidate =
        authorization.count_data.targets_count != null &&
        authorization.count_data.targets_approved_count != null
            ? authorization.count_data.targets_count -
              authorization.count_data.targets_approved_count
            : undefined;
    const canAuthorizationProceed =
        targetsLeftToValidate != null && targetsLeftToValidate <= 0;

    return (
        <section className='w-full'>
            <AuthorizationViewButtons
                authorization={authorization}
                isAuthorizationSignable={isSignable}
                canAuthorizationProceed={canAuthorizationProceed}
                temporaryToken={temporaryToken}
                hideBackButton={hideBackButton}
                hideViewAllAuthorizationButton={hideViewAllAuthorizationButton}
                targets={{
                    filters: targetFilters,
                    sort: targetSort,
                }}
            />
            <section className='grid grid-cols-2 gap-16 items-start my-5'>
                <div>
                    <div className='flex flex-row gap-8'>
                        <div>
                            <span>{project.name}</span>
                            <h2 className='mt-1'>{authorization.name}</h2>
                            <ShowOperation
                                operation={authorization.operation_data}
                            />
                        </div>
                    </div>
                    <div className='flex flex-row gap-8'>
                        <div>
                            <span>
                                {localization.models.authorizationFlow.singular}
                            </span>
                            <h3 className='mt-1'>{authorizationFlow?.name}</h3>
                        </div>
                        <div>
                            <span>
                                {
                                    localization.models.authorizationConfig
                                        .singular
                                }
                            </span>
                            <h3 className='mt-1'>
                                {authorizationConfig?.name}
                            </h3>
                        </div>
                    </div>
                    <div className='grid grid-cols-4 gap-4 text-sm font-bold mt-4'>
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.authorization.status}:
                                </span>
                            }
                            element2={
                                <AuthorizationStatusBadge
                                    authorization={authorization}
                                />
                            }
                            span={2}
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .registeredAt
                                    }
                                    :
                                </span>
                            }
                            element2={
                                authorization.registered_at ? (
                                    <DateBadge
                                        value={authorization.registered_at}
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.authorization.created}:
                                </span>
                            }
                            element2={
                                <DateBadge value={authorization.created_at} />
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .createdBy
                                    }
                                    :
                                </span>
                            }
                            element2={
                                <span>
                                    {getUserDisplayName(
                                        authorization.created_by_data?.[0]
                                    )}
                                </span>
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {localization.fields.authorization.approval}
                                    :
                                </span>
                            }
                            element2={
                                authorization.approved_at ? (
                                    <DateBadge
                                        value={authorization.approved_at}
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .approvedBy
                                    }
                                    :
                                </span>
                            }
                            element2={
                                <span>
                                    {(() => {
                                        const approvedBy =
                                            authorization.authorizer_associations_data?.find(
                                                (aa) => !!aa.response?.accepted
                                            );
                                        if (!approvedBy) return '-';
                                        return getUserDisplayName(
                                            approvedBy?.users_data?.[0]
                                        );
                                    })()}
                                </span>
                            }
                        />
                        <CellGroup
                            element1={
                                <span>
                                    {
                                        localization.fields.authorization
                                            .submitted
                                    }
                                    :
                                </span>
                            }
                            element2={
                                authorization.last_sent_to_authorizers_at ? (
                                    <DateBadge
                                        value={
                                            authorization.last_sent_to_authorizers_at
                                        }
                                    />
                                ) : (
                                    <span>-</span>
                                )
                            }
                        />
                    </div>
                    <ShowDocumentsToSign authorization={authorization} />
                </div>
                {(isRespondable || canSendForApproval || isSignable) &&
                    !canAuthorizationProceed && (
                        <div className='flex flex-col gap-1'>
                            <strong className='ml-auto text-lg'>
                                {localization.components.models.authorization.views.detail.targetsLefToValidate.replace(
                                    '{count}',
                                    (targetsLeftToValidate ?? '-').toString()
                                )}
                            </strong>
                            <span className='ml-auto'>
                                {
                                    localization.components.models.authorization
                                        .views.detail.allTargetsValidated
                                }
                            </span>
                        </div>
                    )}
            </section>
            {!!authorizationFlow?.insert_targets_from_info && (
                <div className='w-[20%] mb-5'>
                    <strong>
                        {
                            localization.components.models.authorization
                                .targetCreationProcessProgress.title
                        }{' '}
                        (
                        {`${
                            authorization.count_data.targets_processed_count ??
                            '-'
                        } /
                            ${authorization.count_data.targets_count ?? '-'}`}
                        )
                    </strong>
                    <ProgressBar
                        value={
                            ((authorization.count_data
                                .targets_processed_count ?? 0) /
                                (authorization.count_data.targets_count ?? 1)) *
                            100
                        }
                        displayValueTemplate={() =>
                            localization.components.models.authorization
                                .targetCreationProcessProgress.complete
                        }
                        showValue={targetEnrichmentComplete}
                    />
                </div>
            )}
            {tabViews}
        </section>
    );
};

export default DetailedAuthorizationView;
