import { useAuth } from 'hooks/context/useAuth';
import useGetDashboardURL from './useGetDashboardEmbed';

/**
 * Hook to get the dashboard overview based on the user's company.
 * Automatically disables the query if the user has no slug configuration or doesn't have permission.
 * @param queryOptions - Additional query options that may be given to the Tanstack's useQuery hook
 * @returns object.query - Query object containing all of the useQueryWithRefresh properties.
 * @returns object.isDisabled - If the query is available for use
 */
const useGetCompanyDashboard = (queryOptions?: { enabled?: boolean }) => {
    const { user } = useAuth();

    const companyName = user.relatedCompany;
    const slug = user.role
        ? companyName?.view_setup?.[user.role]?.dashboard_slug
        : null;

    const isDisabled = queryOptions?.enabled === false || !slug;

    const query = useGetDashboardURL(
        {
            dashboard_slug: slug ?? '',
        },
        {
            enabled: !isDisabled,
        }
    );

    return {
        isDisabled: isDisabled || query.isDisabled,
        query: query.query,
    };
};

export default useGetCompanyDashboard;
